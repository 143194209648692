import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import back from "../assets/images/back.png";
import Spinner from "../components/Spinner";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const FileViewer = () => {
  const [img, setImg] = useState("");
  const [loading, setLoading] = useState(true);
  const [pdfFile, setPdfFile] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const newPlugin = defaultLayoutPlugin();

  const filename = location.state?.file || "";
  const patientid = location.state?.patientUniqueId || "";
  const fileType = location.state?.fileType || "";

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_GLOBAL_BASEURL}/api/commonapi/File_reader/read_file_content_patient`,
          {
            params: { patientid, file: filename },
          }
        );

        if (response.data?.base64String) {
          setImg(response.data.base64String);

          if (fileType === "application/pdf") {
            setPdfFile(response.data.base64String);
          } else {
            setPdfFile(null);
          }
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching file:", error);
        setLoading(false);
      }
    };

    if (filename && patientid && fileType) {
      fetchPdf();
    } else {
      console.error("Missing required parameters: filename, patientid, or fileType");
      setLoading(false);
    }
  }, [filename, patientid, fileType]);

  const backHandler = () => {
    global.OTPValidation = "true";
    navigate("/ViewMedicalRecors", { state: { id: location.state?.id } });
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div style={{ textAlign: "left" }}>
            <img
              src={back}
              alt="Back"
              style={{ height: 20, width: 20, marginTop: 15, marginLeft: 20,marginBottom: 15 }}
              onClick={backHandler}
            />
          </div>
          <div>
            {fileType === "application/pdf" && pdfFile ? (
              <div className="pdf-container">
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                  <Viewer fileUrl={pdfFile} plugins={[newPlugin]} />
                </Worker>
              </div>
            ) : (
                <TransformWrapper
                defaultScale={1}
                defaultPositionX={200}
                defaultPositionY={100}
              >
                <TransformComponent>
                  <div style={{ marginRight: 10 }}>
                    <img
                      style={{
                        height: "auto",
                        width: "auto",
                        marginTop: 70,
                        marginLeft: 7,
                      }}
                      src={img}
                    />
                  </div>
                </TransformComponent>
              </TransformWrapper>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default FileViewer;
