import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import back from '../assets/images/back.png';
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import { message } from 'antd'


export default () => {
    const [smoke, setSmoke] = useState("");
    const [alcohol, setAlcohol] = useState("");
    const [physical_activity, setPhysical_Activity] = useState("");
    const [workpattern, setWorkpattern] = useState("");
    const [diet, setDiet] = useState("");
    const [buttonHide, setButtonHide] = useState(false);
    const [seesionId, setSessionId] = useState(Number);

    const location = useLocation();
    console.log(location.state)
    const navigate = useNavigate();

    useEffect(() => {
        let id = localStorage.getItem("patientid");
        console.log(id);
        setSessionId(id);

        if (location.state.smoke == null) {
            setSmoke("");
        }
        else {
            setSmoke(location.state.smoke);
        }
        if (location.state.alcohol == null) {
            setAlcohol("");
        }
        else {
            console.log(location.state.alcohol);
            setAlcohol(location.state.alcohol);

        }
        if (location.state.physical_activity == null) {
            setPhysical_Activity("");
        }
        else {
            setPhysical_Activity(location.state.physical_activity);
        }
        if (location.state.workpattern == null) {
            setWorkpattern("");
        }
        else {
            setWorkpattern(location.state.workpattern);
        }
        if (location.state.diet == null) {
            setDiet("");
        }
        else {
            setDiet(location.state.diet);
        }


    }, []);

    const handleChangeSmoke = (event) => {

        setSmoke(event.target.value);
    }
    const handleChangeAlcohol = (event) => {

        setAlcohol(event.target.value);
    }
    const handleChangePhysicalActivity = (event) => {

        setPhysical_Activity(event.target.value);
    }
    const handleChangeWorkPattern = (event) => {

        setWorkpattern(event.target.value);
    }
    const handleChangeDiet = (event) => {

        setDiet(event.target.value);
    }




    const updateHandler = (e) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        setButtonHide(true);
        // console.log(seesionId)

        e.preventDefault();
        let formdata = $(e.target);
        formdata = new FormData();
        formdata.append('id', seesionId);
        formdata.append('smoke', smoke);
        formdata.append('alcohol', alcohol);
        formdata.append('physical_activity', physical_activity);
        formdata.append('workpattern', workpattern);
        formdata.append('diet', diet);


        $.ajax({
            type: "POST",
            url: `${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientLifeProfile/update`,
            data: formdata,
            processData: false,
            contentType: false,
            headers: headers,
            success(data) {
                console.log("updated successfully", data);
                setButtonHide(false);
                navigate("/PatientHealthProfile");


            },
            error: function (err) {
                if (err.status === 401) {
                    // Token expired, show message and log out
                    message.error(err.responseJSON.message);
                    logoutHandler();
                } else {
                    console.error('Error occurred:', err.responseText);
                    alert("Error has occurred: ", err.responseText);
                }
            }
        });

    }

    const logoutHandler = () => {
        localStorage.removeItem("phoneNo");
        localStorage.removeItem("patientid");
        localStorage.removeItem("patientLocalImage");
        localStorage.removeItem("patientLocalData");
        localStorage.removeItem("patientUniqueId");
        localStorage.removeItem("chronic_illness_data");
        localStorage.removeItem("allergic_information_data");
        localStorage.removeItem("family_relation_data");
        localStorage.removeItem("family_disease_data");
        localStorage.removeItem("token");

        navigate("/Login");

    }


    let commonChartList = [
        {
            id: 1,
            key: 'Never',
            value: 'Never',

        },
        {
            id: 2,
            key: 'Occasionally',
            value: 'Occasionally',

        },
        {
            id: 3,
            key: 'Frequently',
            value: 'Frequently',

        },
        {
            id: 4,
            key: 'Daily',
            value: 'Daily',

        },
    ]

    let workingShift = [
        {
            id: 1,
            key: 'Day ',
            value: 'Day ',

        },
        {
            id: 2,
            key: 'Night ',
            value: 'Night ',

        },
    ]

    let dietList = [
        {
            id: 1,
            key: 'Veg',
            value: 'Veg',

        },
        {
            id: 2,
            key: 'NonVeg',
            value: 'NonVeg',

        },
    ]




    return (
        <div>
            <div style={{ textAlign: 'left' }}>

                <img src={back} style={{ height: 20, width: 20, marginTop: 20, marginLeft: 20 }} onClick={() => navigate("/PatientHealthProfile")} />
            </div>
            <p style={{ fontSize: 20, fontWeight: 'bold', color: '#A696FF', marginTop: -10 }}>Edit Life Style</p>

            <div className='first-div'>

                <div style={{ marginTop: 15 }} className="flex-div">
                    <div style={{ width: '40%', textAlign: 'left' }}>

                        <p className="label_style" >Smoking:</p>
                    </div>
                    <div style={{ width: '60%' }}>

                        <div style={{ width: '95%' }} className="select-wrapper">
                            <select
                                name="smoke"
                                value={smoke}
                                onChange={(event) => handleChangeSmoke(event)}

                                className="input-bordered"
                                style={{ fontSize: 13, fontWeight: 'bold' }}

                            ><option value="" >select</option>
                                {
                                    commonChartList.map((val) => {
                                        return (

                                            <option >{val.value}</option>

                                        )
                                    })

                                }
                            </select>
                        </div>
                    </div>

                </div>

                <div style={{ marginTop: 15 }} className="flex-div">
                    <div style={{ width: '40%', textAlign: 'left' }}>

                        <p className="label_style" >    Alcohol:</p>
                    </div>
                    <div style={{ width: '60%' }}>

                        <div style={{ width: '95%' }} className="select-wrapper">
                            <select
                                name="alcohol"
                                value={alcohol}
                                onChange={(event) => handleChangeAlcohol(event)}

                                className="input-bordered"
                                style={{ fontSize: 13, fontWeight: 'bold' }}

                            ><option value="" >select</option>
                                {
                                    commonChartList.map((val) => {
                                        return (

                                            <option >{val.value}</option>

                                        )
                                    })

                                }
                            </select>
                        </div>
                    </div>

                </div>

                <div style={{ marginTop: 15 }} className="flex-div">
                    <div style={{ width: '40%', textAlign: 'left' }}>

                        <p className="label_style" > Exercise:</p>
                    </div>
                    <div style={{ width: '60%' }}>

                        <div style={{ width: '95%' }} className="select-wrapper">
                            <select
                                name="physical_activity"
                                value={physical_activity}
                                onChange={(event) => handleChangePhysicalActivity(event)}

                                className="input-bordered"
                                style={{ fontSize: 13, fontWeight: 'bold' }}

                            ><option value="">select</option>
                                {
                                    commonChartList.map((val) => {
                                        return (

                                            <option >{val.value}</option>

                                        )
                                    })

                                }
                            </select>
                        </div>
                    </div>

                </div>


                <div style={{ marginTop: 15 }} className="flex-div">
                    <div style={{ width: '40%', textAlign: 'left' }}>

                        <p className="label_style" > Work Pattern:</p>
                    </div>
                    <div style={{ width: '60%' }}>

                        <div style={{ width: '95%' }} className="select-wrapper">
                            <select
                                name="workpattern"
                                value={workpattern}
                                onChange={(event) => handleChangeWorkPattern(event)}

                                className="input-bordered"
                                style={{ fontSize: 13, fontWeight: 'bold' }}

                            ><option value="">select</option>
                                {
                                    workingShift.map((val) => {
                                        return (

                                            <option >{val.value}</option>

                                        )
                                    })

                                }
                            </select>
                        </div>
                    </div>

                </div>


                <div style={{ marginTop: 15 }} className="flex-div">
                    <div style={{ width: '40%', textAlign: 'left' }}>

                        <p className="label_style" > Diet :</p>
                    </div>
                    <div style={{ width: '60%' }}>

                        <div style={{ width: '95%' }} className="select-wrapper">
                            <select
                                name="diet"
                                value={diet}
                                onChange={(event) => handleChangeDiet(event)}

                                className="input-bordered"
                                style={{ fontSize: 13, fontWeight: 'bold' }}

                            ><option value="">select</option>
                                {
                                    dietList.map((val) => {
                                        return (

                                            <option >{val.value}</option>

                                        )
                                    })

                                }
                            </select>
                        </div>
                    </div>

                </div>









                <form method="post"
                    onSubmit={(event) => updateHandler(event)}
                >
                    {
                        buttonHide == false ?
                            <button type="submit" style={{ marginTop: 30 }} className="btn btn-auto btn-primary">
                                Update
                            </button>
                            :
                            <p style={{ fontSize: 14, color: 'green', fontWeight: 'bold', marginTop: 15 }}>Updating...</p>

                    }


                </form>

            </div>

        </div>
    )

}