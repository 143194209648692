import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import Axios from 'axios';
import $ from "jquery";
import FormSubmitPopup from '../components/FormSubmitPopup';
import '../css/Global.css';

export default () => {

    const [patientId, setPatientId] = useState(Number);
    const [constId, setConstId] = useState("");
    const [patientBasicInfo, setPatientBasicInfo] = useState({});
    const [age, setAge] = useState("");
    const [gender, setGender] = useState("");
    const [email, setEmail] = useState("");
    const [locality, setLocality] = useState("");
    const [state, setState] = useState("West Bengal");
    const [contact_name, setContact_name] = useState("");
    const [contact_phoneno, setContact_phoneno] = useState("");
    const [tobaco, setTobaco] = useState("");
    const [alcohol, setAlcohol] = useState("");
    const [exercise, setExercise] = useState("");
    const [disease1, setDisease1] = useState("");
    const [disease2, setDisease2] = useState("");
    const [relation1, setRelation1] = useState("");
    const [relation2, setRelation2] = useState("");
    const [allergies, setAllergies] = useState("");
    const [diabetes, setDiabetes] = useState("");
    const [hiv, setHiv] = useState("");
    const [buttonHide, setButtonHide] = useState(false);
    const [showConfirmMsg, setShowConfirmMsg] = useState(false);
    const [alreadySubmitted, setIsAlreadySubmitted] = useState(false);
    const [msg, setMsg] = useState("");
    const [otpno, setOtpNo] = useState("");
    const [formHide, setFormHide] = useState(true);
    const [Validating, setValidating] = useState(false);
    const [isOTPSectionOpen, setIsOTPSectionOpen] = useState(false);
    const [isSubmitButtonHide, setIsSubmitButtonHide] = useState(false);
    const [OTP, setOTP] = useState(new Array(6).fill(""));
    const [successOTPMsg, setSuccessOTPMsg] = useState("");
    const [checkinFormFilledUp, setCheckinFormFilledUp] = useState("");
    const [name, setName] = useState("");
    const [contactRelation, setContactRelation] = useState("");
    const [CPIId, setCPIId] = useState("");
    const [otherDisease, setOtherDisease] = useState("");
    const [medication, setMedication] = useState("");
    const [allergiesInfo, setAllergiesInfo] = useState("");
    const [diabetesInfo, setDiabetesInfo] = useState("");
    const [hivInfo, setHivInfo] = useState("");
    const [relation, setRelation] = useState([]);
    const [familyHistoryDisease, setFamilyHistoryDisease] = useState([]);
    const [phoneNo, setPhoneNo] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [isUpdate, setIsUpdate] = useState(false);
    const [displayOTP, setDisplayOTP] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");
    const [diet, setDiet] = useState("");
    const [isPopupSectionOpen, setIsPopupSectionOpen] = useState(false);
    const [uniqueId, setUniqueId] = useState("");
    const [isFormForself, setIsFormForself] = useState(true);
    const [isSelfAppointment, setIsSelfAppointment] = useState("");
    const [isUnderSixteen, setIsUnderSixteen] = useState(false);

    // 100&2&Smart Clinic

    let paramsid, clinicName, clinicId;
    const searchid = useLocation().search;
    console.log(searchid)
    paramsid = new URLSearchParams(searchid).get('text');
    let decodedstring = base64_decode(paramsid);
    console.log("decodedstring ", decodedstring);

    let clinicPatientId = decodedstring.split("&")[0];
    console.log("clinicPatientId : ", clinicPatientId);

    clinicId = decodedstring.split("&")[1];
    console.log("clinicId : ", clinicId);

    clinicName = decodedstring.split("&")[2];
    console.log("clinicName : ", clinicName);

    const handleCheckboxChange = (event) => {
        setIsUnderSixteen(event.target.checked);
      };


    const handleChange = (element, index) => {
        console.log(element.value)
        if (isNaN(element.value)) return false;

        setOTP([...OTP.map((d, idx) => (idx === index ? element.value : d))]);
        //setOtp(enteredOtp.join(""));

        //Focus next input
        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    useEffect(() => {


        Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/clinicapi/Patients/get_details_from_clinic_patient?cpid=${clinicPatientId}`).then((response) => {
            console.log("patientBasicInfo ", response.data.response);
            if (response.data.status == '1') {
                setFormHide(true);
                setIsAlreadySubmitted(false);
                setPatientBasicInfo(response.data.response);
                setAge(response.data.response?.age);
                setGender(response.data.response?.gender);
                setEmail(response.data.response?.email);
                setCheckinFormFilledUp(response.data.response?.is_checkin_form_filledup);
                setName(response.data.response?.name);
                setCPIId(response.data.response?.cp_id);
                setPhoneNo(response.data.response?.phone_number);
            }
            Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/commonapi/PickList/get_family_ralation`).then((response) => {
                console.log(response.data);
                if (response.data.status == '1') {
                    setRelation(response.data.response);
                }
            });
            Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/commonapi/PickList/get_family_disease`).then((response) => {
                console.log(response.data);
                if (response.data.status == '1') {
                    setFamilyHistoryDisease(response.data.response);
                }
            });
        });



    }, []);
    const hivInfoChangeHandler = (e) => {

        setHivInfo(e.target.value);

    }
    const diabetesInfoChangeHandler = (e) => {

        setDiabetesInfo(e.target.value);

    }
    const allergiesInfoChangeHandler = (e) => {

        setAllergiesInfo(e.target.value);

    }
    const medicationChangeHandler = (e) => {
        setMedication(e.target.value);

    }

    const otherDiseaseChangeHandler = (e) => {
        setOtherDisease(e.target.value);

    }
    const nameChangeHandler = (e) => {
        setIsUpdate(true);
        setValidating(true);
        setName(e.target.value);

    }
    const ageChangeHandler = (e) => {
        setIsUpdate(true);
        setValidating(true);
        setAge(e.target.value);

    }
    const genderChangeHandler = (e) => {
        setIsUpdate(true);
        setValidating(true);
        setGender(e.target.value);

    }
    const emailChangeHandler = (e) => {
        setIsUpdate(true);
        setValidating(true);
        setEmail(e.target.value);

    }
    const localityChangeHandler = (e) => {
        setLocality(e.target.value);

    }
    const stateChangeHandler = (e) => {
        setState(e.target.value);

    }

    const contactNameChangeHandler = (e) => {
        setContact_name(e.target.value);

    }
    const contactPhonenoChangeHandlerForLess16Years = (e) => {
        setContact_phoneno(e.target.value);

    }
    const tobacoChangeHandler = (e) => {
        setTobaco(e.target.value);

    }
    const alcoholChangeHandler = (e) => {
        setAlcohol(e.target.value);

    }
    const exerciseChangeHandler = (e) => {
        setExercise(e.target.value);

    }
    const disease1ChangeHandler = (e) => {
        setDisease1(e.target.value);

    }
    const disease2ChangeHandler = (e) => {
        setDisease2(e.target.value);

    }
    const relation1ChangeHandler = (e) => {
        setRelation1(e.target.value);

    }
    const relation2ChangeHandler = (e) => {
        setRelation2(e.target.value);

    }
    const allergyChangeHandler = (e) => {
        console.log(e.target.value)
        setAllergies(e.target.value);

    }
    const diabetesChangeHandler = (e) => {
        setDiabetes(e.target.value);

    }
    const hivChangeHandler = (e) => {
        setHiv(e.target.value);

    }
    const dietChangeHandler = (e) => {
        setDiet(e.target.value);

    }
    const contactRelationChangeHandler = (e) => {
        setContactRelation(e.target.value);

    }
    const phonenoChangeHandler = (e) => {
        setIsUpdate(true);
        setValidating(true);
        setPhoneNo(e.target.value);
    }
    // let clinicName = localStorage.getItem("clinic_name");
    // console.log(clinicName)
    // let clinicId = localStorage.getItem("clinic_id");
    // console.log(clinicId)
    let myStyle_otp = {
        // display: "none",
        backgroundColor: '#E0E8F3'
    };


    let arraySmoke = [
        {
            id: 1,
            key: '',
            value: 'Never',

        },
        {
            id: 2,
            key: '',
            value: 'Occasionally',

        },
        {
            id: 3,
            key: '',
            value: 'Frequently',

        },
        {
            id: 4,
            key: '',
            value: 'Daily',

        },
    ]


    let arrayAlcohol = [
        {
            id: 1,
            key: '',
            value: 'Never',

        },
        {
            id: 2,
            key: '',
            value: 'Occasionally',

        },
        {
            id: 3,
            key: '',
            value: 'Frequently',

        },
        {
            id: 4,
            key: '',
            value: 'Daily',

        },
    ]


    let arrayExercise = [
        {
            id: 1,
            key: '',
            value: 'Never',

        },
        {
            id: 2,
            key: '',
            value: 'Occasionally',

        },
        {
            id: 3,
            key: '',
            value: 'Frequently',

        },
        {
            id: 4,
            key: '',
            value: 'Daily',

        },
    ]


    const otpSubmitHandler = (e) => {
        setIsSubmitButtonHide(true);
        e.preventDefault();
        const enteredOtp = OTP.join("");
        console.log(enteredOtp);
        if (otpno == enteredOtp) {
            setIsOTPSectionOpen(false);
            setIsSubmitButtonHide(true);
            Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/clinicapi/Patients/get_check_if_patients_exist_or_not_by_phone?phoneNumber=${phoneNo}&gender=${gender}&age=${age}`).then((response) => {
                console.log(response.data);
                if (response.data.status == 0) {
                    setSuccessOTPMsg("Your mobile no has been successfully validated. Please submit this form to further proceed.")
                    setFormHide(false);
                    // setFamilyHistoryDisease(response.data.response);
                }
                else {
                    setIsPopupSectionOpen(true);
                    setUniqueId(response.data.response.up_id);
                    setIsSelfAppointment(response.data.isSelf);
                }
            });

        } else {
            setSuccessOTPMsg("OTP didn't match");
            setIsSubmitButtonHide(false);
            setFormHide(true);


        }


    }


    const validateMobileHandler = (e) => {
        setValidating(true);

        e.preventDefault();
        let formdata = $(e.target);
        formdata = new FormData();
        console.log("phone no", phoneNo);
        formdata.append('phone_number', phoneNo);
        formdata.append('clinic_id', clinicId);

        $.ajax({
            type: "POST",
            url: `${process.env.REACT_APP_GLOBAL_BASEURL}/api/clinicapi/Patients/check_ifPhonenumber_valid`,
            data: formdata,
            processData: false,
            contentType: false,
            success(data) {
                console.log(data);

                if (data == "validate") {
                    setValidating(true);

                    Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/commonapi/Misc/sendotp?phone=${phoneNo}`)
                        .then(OTPresponse => {
                            console.log('send OTPHandler:otp response \n', OTPresponse.data);
                            if (OTPresponse.data) {

                                if (OTPresponse.data.OTP_SMS_RESPONSE == "No") {
                                    setOtpNo(OTPresponse.data.OTP);
                                    setIsOTPSectionOpen(true);
                                    setDisplayOTP(true);
                                } else {
                                    setOtpNo(OTPresponse.data.OTP);
                                    setIsOTPSectionOpen(true);
                                    setDisplayOTP(false);

                                }

                            }

                        }).catch((err) => {
                            console.log(' otp send failed', err);

                        })


                }
                else {
                    setErrorMsg("Phone number already exists");
                }

            },
            error: function (err) {
                console.log(err);
                alert("error ", err);

            }
        });


    }

    const resendOTP = () => {
        Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/commonapi/Misc/sendotp?phone=${phoneNo}`)
            .then(OTPresponse => {
                console.log('resend OTPHandler:otp response \n', OTPresponse.data);
                if (OTPresponse.data) {

                    if (OTPresponse.data.OTP_SMS_RESPONSE == "No") {
                        setOtpNo(OTPresponse.data.OTP);
                        setIsOTPSectionOpen(true);
                        setDisplayOTP(true);

                    } else {
                        setOtpNo(OTPresponse.data.OTP);
                        setIsOTPSectionOpen(true);
                        setDisplayOTP(false);

                    }


                }

            }).catch((err) => {
                console.log(' otp send failed', err);

            })
    }

    const patientDataSaveHandler = (e) => {
        const patientData = [{
            name: name, gender: gender, phone_number: phoneNo, email: email, age: age, locality: locality, state: state, emergency_contact_name: contact_name
            , emergency_contact_number: contact_phoneno, emergency_contact_relation: contactRelation, smoke: tobaco, alcohol: alcohol, physical_activity: exercise, family_histry_1_disease: disease1,
            family_history_1_relation: relation1, family_history_2_disease: disease2, family_history_2_relation: relation2, allergies: allergies,
            diabetes: diabetes, hiv_aids: hiv, medication: medication, other_disease: otherDisease, allergies_info: allergiesInfo, diabetes_info: diabetesInfo,
            hiv_aids_info: hivInfo, diet: diet

        }]
        console.log("patientData: ", JSON.stringify(patientData));
        // if (relation1 != "" || relation2 != "") {
        setMsg("");
        setButtonHide(true);
        e.preventDefault();
        let formdata = $(e.target);
        formdata = new FormData();

        formdata.append('patient_id', clinicPatientId);
        formdata.append('isFormForSelf', isFormForself);
        formdata.append('up_id', uniqueId);
        // formdata.append('consultation_id', clinicPatientId[1]);
        formdata.append('gender', gender);
        formdata.append('name', name);
        formdata.append('checkin_form', checkinFormFilledUp);
        formdata.append('phone_number', phoneNo);
        formdata.append('email', email);
        formdata.append('age', age);
        formdata.append('locality', locality);
        formdata.append('state', state);
        formdata.append('emergency_contact_name', contact_name);
        formdata.append('emergency_contact_number', contact_phoneno);
        formdata.append('tobaco', tobaco);
        formdata.append('alcohol', alcohol);
        formdata.append('physical_activity', exercise);
        formdata.append('diet', diet);
        formdata.append('family_history_1_disease', disease1);
        formdata.append('family_history_1_relation', relation1);
        formdata.append('family_history_2_disease', disease2);
        formdata.append('family_history_2_relation', relation2);
        formdata.append('allergies', allergies);
        formdata.append('diabetes', diabetes);
        formdata.append('hiv_aids', hiv);
        formdata.append('contact_relation', contactRelation);
        formdata.append('medication', medication);
        formdata.append('other_disease', otherDisease);
        if (allergies == '1') {
            formdata.append('allergies_info', allergiesInfo);
        }
        else {
            formdata.append('allergies_info', "");
        }
        if (diabetes == '1') {
            formdata.append('diabetes_info', diabetesInfo);
        }
        else {
            formdata.append('diabetes_info', "");
        }
        if (hiv == '1') {
            formdata.append('hiv_aids_info', hivInfo);
        }
        else {
            formdata.append('hiv_aids_info', "");
        }
        formdata.append('checkin_form_data', JSON.stringify(patientData[0]));
        $.ajax({
            type: "POST",
            url: `${process.env.REACT_APP_GLOBAL_BASEURL}/api/clinicapi/Patients/onboarding`,
            data: formdata,
            processData: false,
            contentType: false,
            success(data) {
                console.log(data);
                // setButtonHide(false);
                setShowConfirmMsg(true);

            },
            error: function (err) {
                console.log(err);
                alert("Patient onboarded and checkedin failed  ", err);

            }
        });

    }



    const updateHandeler = (value) => {
        setSuccessMsg("");
        setValidating(false);
        setIsUpdate(false);
        // setSavingMsg(true);
        value.preventDefault();
        let formdata = $(value.target);
        formdata = new FormData();
        formdata.append('phone_number', phoneNo);
        formdata.append('name', name);
        formdata.append('gender', gender);
        formdata.append('age', age);
        formdata.append('email', email);
        formdata.append('patient_id', CPIId);
        $.ajax({
            type: "POST",
            url: `${process.env.REACT_APP_GLOBAL_BASEURL}/api/clinicapi/ClinicPatients/update`,
            data: formdata,
            processData: false,
            contentType: false,
            success(data) {
                console.log(data);
                // setSavingMsg(false);
                if (data) {
                    setSuccessMsg("Update Successful");
                    setTimeout(function () {
                        setSuccessMsg("");
                    }, 2000);

                    // setButtonHide(false);
                    // setUpdateMsg("Update successful");
                }
            },
            error: function (err) {
                console.log(err);
                // setSavingMsg(false);
                // setUpdateMsg("");
            }
        });

    }

    const yesHandler = () => {
        setIsFormForself(true);
        setSuccessOTPMsg("Your mobile no has been successfully validated. Please submit this form to further proceed.")
        setFormHide(false);
        setIsPopupSectionOpen(false);
    }
    const noHandler = () => {
        setIsFormForself(false);
        setSuccessOTPMsg("Your mobile no has been successfully validated. Please submit this form to further proceed.")
        setFormHide(false);
        setIsPopupSectionOpen(false);
    }


    return (
        <>
            <div style={{ marginTop: 20, marginLeft: 10, marginRight: 10 }}>


                {checkinFormFilledUp == "0" ?
                    <>


                        {showConfirmMsg == false ?

                            <div className="checkin-form">
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <h6 className="card-title" style={{ marginTop: 15, marginLeft: 'auto', marginRight: 'auto', color:'#F96E2A' }}>New Patient Form</h6>
                                </div>
                                <div>
                                    <h6 className="card-title" style={{ marginTop: 15, width: '100%', textAlign: 'left', marginLeft: 20 , color:'#F96E2A'}}>{clinicName}</h6>

                                </div>

                                <div style={{ paddingLeft: 20, paddingRight: 20, marginTop: 20 }} >
                                    <h6 style={{ color: 'black', marginTop: 15, textAlign: 'left', fontSize: 16, fontWeight: 'bold' }}>Personal Details</h6>

                                    <div className="row" >

                                        <div className="col-md-6">
                                            <div className="input-item input-with-label" style={{ textAlign: 'left' }}>
                                                <label className="input-item-label text-exlight" style={{ color: 'black !important', fontSize: '16px'}}> Name: </label>
                                                <input
                                                    type="text"
                                                    className="input-bordered"
                                                    name="name"
                                                    value={name}
                                                    onChange={(event) => nameChangeHandler(event)}
                                                    disabled
                                                    maxLength={50}
                                                    style={{
                                                        backgroundColor: '#e6e6e6', // Set background color for input
                                                        color: '#2A3663', // Input text color
                                                        fontSize: '14px', // Input text size (slightly smaller than the label)
                                                        border: '1px solid #3F72AF', // Optional: Border matching the label color
                                                        padding: '10px', // Add some padding for better spacing
                                                        borderRadius: '4px', // Optional: Rounded corners
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-item input-with-label" style={{ textAlign: 'left' }}>
                                                <label className="input-item-label text-exlight" style={{ color: '#3F72AF', fontSize: '16px'}} >Clinic Patient ID:</label>
                                                <input
                                                    type="text"
                                                    className="input-bordered"
                                                    value={CPIId}
                                                    disabled
                                                    style={{
                                                        backgroundColor: '#e6e6e6', // Set background color for input
                                                        color: '#2A3663', // Input text color
                                                        fontSize: '14px', // Input text size (slightly smaller than the label)
                                                        border: '1px solid #3F72AF', // Optional: Border matching the label color
                                                        padding: '10px', // Add some padding for better spacing
                                                        borderRadius: '4px', // Optional: Rounded corners
                                                    }}
                                                />
                                                {/* <p style={{ fontSize: 13, marginTop: -1 }} className="value">&nbsp;{CPIId} </p> */}
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="input-item input-with-label" style={{ textAlign: 'left' }}>
                                                <label className="input-item-label text-exlight" style={{ color: '#3F72AF', fontSize: '16px'}} >Gender: </label>
                                                {/* <input
                                                    type="text"
                                                    className="input-bordered"
                                                    name="gender"
                                                    value={gender}
                                                    onChange={(event) => genderChangeHandler(event)}
                                                    disabled
                                                    style={{
                                                        backgroundColor: '#e6e6e6', // Set background color for input
                                                        color: '#2A3663', // Input text color
                                                        fontSize: '14px', // Input text size (slightly smaller than the label)
                                                        border: '1px solid #3F72AF', // Optional: Border matching the label color
                                                        padding: '10px', // Add some padding for better spacing
                                                        borderRadius: '4px', // Optional: Rounded corners
                                                    }}
                                                /> */}
                                                {/* <label className="input-item-label text-exlight">Gender *</label> */}
                                                <div className="select-wrapper">
                                                    <select className="input-bordered"

                                                        name="gender"
                                                        value={gender}
                                                        required
                                                        onChange={(event) => genderChangeHandler(event)}
                                                        style={{
                                                            backgroundColor: '#F5F5F5', // Set background color for input
                                                            color: '#2A3663', // Input text color
                                                            fontSize: '14px', // Input text size (slightly smaller than the label)
                                                            border: '1px solid #3F72AF', // Optional: Border matching the label color
                                                            padding: '10px', // Add some padding for better spacing
                                                            borderRadius: '4px', // Optional: Rounded corners
                                                        }}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-item input-with-label" style={{ textAlign: 'left' }}>
                                                <label className="input-item-label text-exlight" style={{ color: '#3F72AF', fontSize: '16px'}}>Age: </label>
                                                <input
                                                    type="tel"
                                                    className="input-bordered"
                                                    name="age"
                                                    value={age}
                                                    required
                                                    maxlength="3"
                                                    onChange={(event) => ageChangeHandler(event)}
                                                    style={{
                                                        backgroundColor: '#F5F5F5', // Set background color for input
                                                        color: '#2A3663', // Input text color
                                                        fontSize: '14px', // Input text size (slightly smaller than the label)
                                                        border: '1px solid #3F72AF', // Optional: Border matching the label color
                                                        padding: '10px', // Add some padding for better spacing
                                                        borderRadius: '4px', // Optional: Rounded corners
                                                    }}
                                                />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="input-item input-with-label" style={{ textAlign: 'left' }}>
                                                <label className="input-item-label text-exlight" style={{ color: '#3F72AF', fontSize: '16px'}}>Email: </label>
                                                <input
                                                    type="text"
                                                    className="input-bordered"
                                                    name="email"
                                                    value={email}
                                                    onChange={(event) => emailChangeHandler(event)}
                                                    maxLength={40}
                                                    style={{
                                                        backgroundColor: '#F5F5F5', // Set background color for input
                                                        color: '#2A3663', // Input text color
                                                        fontSize: '14px', // Input text size (slightly smaller than the label)
                                                        border: '1px solid #3F72AF', // Optional: Border matching the label color
                                                        padding: '10px', // Add some padding for better spacing
                                                        borderRadius: '4px', // Optional: Rounded corners
                                                    }}
                                                />
                                            </div>

                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-item input-with-label" style={{ textAlign: 'left' }}>
                                                <label className="input-item-label text-exlight" style={{ color: '#3F72AF', fontSize: '16px'}}> Phone number: </label>
                                                <input
                                                    type="tel"
                                                    className="input-bordered"
                                                    name="contact_phoneno"
                                                    value={phoneNo}
                                                    maxLength={10}
                                                    onChange={(event) => phonenoChangeHandler(event)}
                                                    disabled
                                                    style={{
                                                        backgroundColor: '#e6e6e6', // Set background color for input
                                                        color: '#2A3663', // Input text color
                                                        fontSize: '14px', // Input text size (slightly smaller than the label)
                                                        border: '1px solid #3F72AF', // Optional: Border matching the label color
                                                        padding: '10px', // Add some padding for better spacing
                                                        borderRadius: '4px', // Optional: Rounded corners
                                                    }}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: 10, marginLeft: 'auto', marginRight: 'auto' }}>

                                        {errorMsg ? <p style={{ fontSize: 13, fontWeight: 'bold', color: 'red' }}>{errorMsg}</p> : null}
                                    </div><div style={{ marginTop: 10, marginLeft: 'auto', marginRight: 'auto' }}>

                                        {successMsg ? <p style={{ fontSize: 13, fontWeight: 'bold', color: 'green' }}>{successMsg}</p> : null}
                                    </div>

                                    <div style={{ marginTop: 20 }}>
                                        {
                                            Validating == false ?

                                                <form
                                                    method="post"
                                                    onSubmit={(event) => validateMobileHandler(event)}

                                                >
                                                    <button type="submit" className="btn btn-auto btn-primary">Validate Phone Number</button>

                                                </form>
                                                :
                                                null
                                        }
                                        {
                                            isUpdate == true ?
                                                <form
                                                    method="post"
                                                    onSubmit={(event) => updateHandeler(event)}

                                                >
                                                    <button type="submit" className="btn btn-auto btn-success">Update Details</button>

                                                </form>
                                                :
                                                null
                                        }


                                    </div>

                                </div>
                                {
                                    isOTPSectionOpen == true ?
                                        <div className="page-ath-form" id="otp" style={myStyle_otp}>
                                            <div className="card-head">
                                                <h6 className="card-title" style={{ marginTop: 15 }}>OTP</h6>
                                            </div>
                                            <div className="input-item">
                                                {displayOTP == true ?
                                                    <p> Your Current OTP is: {otpno}</p> : null
                                                }

                                                {/* <input
                                            style={{ marginTop: 10 }}
                                            type="text"
                                            name="otp"
                                            required
                                            placeholder="OTP"
                                            className="input-bordered"
                                        // value={otp}
                                        // onChange={onotpChange}
                                        /> */}



                                            </div>

                                            {OTP.map((data, index) => {
                                                return (
                                                    <input
                                                        className="otp-field"
                                                        type="number"
                                                        name="otp"
                                                        maxLength="1"
                                                        key={index}
                                                        value={data}
                                                        style={{ width: 40, paddingLeft: 10, marginLeft: 10, fontWeight: 'bold', fontSize: 12, marginTop: 10 }}
                                                        onChange={(event) => handleChange(event.target, index)}
                                                        onFocus={e => e.target.select()}
                                                    />
                                                );
                                            })}
                                            {/* {errorMessage && <div className="error"> <p style={{ color: 'red', fontSize: 14, fontWeight: 'bold' }}>{errorMessage}</p> </div>} */}

                                            <br />
                                            <div onClick={resendOTP} style={{ marginTop: 15, cursor: 'pointer' }}>
                                                <a className="resendbutton" style={{ color: '#253992' }}>Resend OTP?</a>

                                            </div>
                                            <form method="post" onSubmit={(event) => otpSubmitHandler(event)}>
                                                <div style={{ marginTop: 20 }}>
                                                    {isSubmitButtonHide == false ?
                                                        <button
                                                            type="submit"
                                                            // onClick={onsubmitform_otp}
                                                            className="btn btn-primary"
                                                        >
                                                            Submit
                                                        </button>
                                                        :
                                                        <p style={{ marginTop: 10, fontSize: 13, fontWeight: 'bold', color: 'green' }}>Submitting...</p>

                                                    }
                                                </div>
                                            </form>
                                        </div>
                                         :
                                        null
                                } 

                                {successOTPMsg ? <p style={{ fontSize: 13, fontWeight: 'bold', color: 'black', marginTop: 10 }}>{successOTPMsg}</p> : null}

                                {isPopupSectionOpen ?

                                    <>
                                        <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 20, borderRadius: 5, backgroundColor: '#F1F3F4', width: '70%', height: 200 }}>
                                            <p style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'left', marginLeft: 10, paddingTop: 20 }}>Your phone number is already registered in SwasthaYog global patient registry. Unique id is {uniqueId}</p>
                                            <p>Are you submitting form for whom you want to?
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 20 }}>
                                                    {
                                                        isSelfAppointment == "no" ?
                                                            <button onClick={noHandler} style={{ marginLeft: 10 }} className="commonbutton">For family member's</button>
                                                            :
                                                            <button onClick={yesHandler} className="commonbutton">For self</button>
                                                    }
                                                </div>
                                                {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 20 }}>
                                                    <button onClick={yesHandler} className="commonbutton">Yes</button>
                                                    <button onClick={noHandler} style={{ marginLeft: 10 }} className="commonbutton">No (For family member)</button>
                                                </div> */}

                                            </p>
                                        </div>

                                    </>
                                    :
                                    null


                                }

                                {
                                    formHide == false ?
                                        <>


                                            <div style={{ paddingLeft: 20, paddingRight: 20, marginTop: 20 }} className="row">

                                                <div className="col-md-6">
                                                    <div className="input-item input-with-label" style={{ textAlign: 'left' }}>
                                                        <label className="input-item-label text-exlight" style={{ color: '#3F72AF', fontSize: '16px'}}>Address (Street no/Flat no/Locality/Post office): </label>
                                                        <input
                                                            type="text"
                                                            className="input-bordered"
                                                            name="locality"
                                                            value={locality}
                                                            onChange={(event) => localityChangeHandler(event)}
                                                            maxLength={50}
                                                            style={{
                                                                backgroundColor: '#F5F5F5', // Set background color for input
                                                                color: '#2A3663', // Input text color
                                                                fontSize: '14px', // Input text size (slightly smaller than the label)
                                                                border: '1px solid #3F72AF', // Optional: Border matching the label color
                                                                padding: '10px', // Add some padding for better spacing
                                                                borderRadius: '4px', // Optional: Rounded corners
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6">



                                                    <div className="input-item input-with-label" style={{ textAlign: 'left' }}>
                                                        <label className="input-item-label text-exlight" style={{ color: '#3F72AF', fontSize: '16px'}} >State: </label>
                                                        <div className="select-wrapper">
                                                            <select className="input-bordered"
                                                                name="state" value={state}
                                                                style={{
                                                                    backgroundColor: '#F5F5F5', // Set background color for input
                                                                    color: '#2A3663', // Input text color
                                                                    fontSize: '14px', // Input text size (slightly smaller than the label)
                                                                    border: '1px solid #3F72AF', // Optional: Border matching the label color
                                                                    padding: '10px', // Add some padding for better spacing
                                                                    borderRadius: '4px', // Optional: Rounded corners
                                                                }}
                                                                onChange={(event) => stateChangeHandler(event)}
                                                            >
                                                                <option value="West Bengal">West Bengal</option>
                                                                <option value="Delhi">Delhi</option>
                                                                <option value="Bihar">Bihar</option>
                                                                <option value="Odisha">Odisha</option>
                                                                <option value="Maharastra">Maharastra</option>
                                                                <option value="Tamilnadu">Tamilnadu</option>
                                                                <option value="Jharkhand">Jharkhand</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>


                                    {/* <p className="input-item-label text-exlight">If you are under 16 provide parents/guardians name and phone number</p> */}
                                    <p className="input-item-label text-exlight" style={{ marginLeft: '20px', marginRight: '20px', marginTop:'5px', textAlign: 'left' }}>If you are under 16, please confirm by checking this box.<input type="checkbox" style={{ marginLeft: "10px", width: "10px", height: "10px", transform: "scale(1.5)", cursor: "pointer" }} id="underSixteenConfirmation" name="underSixteenConfirmation" checked={isUnderSixteen} onChange={handleCheckboxChange} /></p>
                                    {isUnderSixteen ? <div style={{ paddingLeft: 20, paddingRight: 20, marginTop: 5 }} className="row">

                                        <div className="col-md-4">
                                            <div className="input-item input-with-label" style={{ textAlign: 'left' }}>
                                                <label className="input-item-label text-exlight" style={{ color: '#3F72AF', fontSize: '16px' }}>Parents Phone number:</label>
                                                <input
                                                    type="tel"
                                                    className="input-bordered"
                                                    name="contact_phoneno"
                                                    value={contact_phoneno}
                                                    onChange={(event) => contactPhonenoChangeHandlerForLess16Years(event)}
                                                    maxLength={10}
                                                    style={{
                                                        backgroundColor: '#F5F5F5', // Set background color for input
                                                        color: '#2A3663', // Input text color
                                                        fontSize: '14px', // Input text size (slightly smaller than the label)
                                                        border: '1px solid #3F72AF', // Optional: Border matching the label color
                                                        padding: '10px', // Add some padding for better spacing
                                                        borderRadius: '4px', // Optional: Rounded corners
                                                    }}
                                                />

                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="input-item input-with-label" style={{ textAlign: 'left' }}>
                                                <label className="input-item-label text-exlight" style={{ color: '#3F72AF', fontSize: '16px' }} >Parents Name:  </label>
                                                <input
                                                    type="text"
                                                    className="input-bordered"
                                                    name="contact_name"
                                                    value={contact_name}
                                                    onChange={(event) => contactNameChangeHandler(event)}
                                                    maxLength={40}
                                                    style={{
                                                        backgroundColor: '#F5F5F5', // Set background color for input
                                                        color: '#2A3663', // Input text color
                                                        fontSize: '14px', // Input text size (slightly smaller than the label)
                                                        border: '1px solid #3F72AF', // Optional: Border matching the label color
                                                        padding: '10px', // Add some padding for better spacing
                                                        borderRadius: '4px', // Optional: Rounded corners
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="input-item input-with-label" style={{ textAlign: 'left' }}>
                                                <label className="input-item-label text-exlight" style={{ color: '#3F72AF', fontSize: '16px' }} >Relationship to the Patient: </label>
                                                <select
                                                    name="relation1"
                                                    value={contactRelation}
                                                    onChange={(event) => contactRelationChangeHandler(event)}

                                                    className="input-bordered"
                                                    style={{
                                                        backgroundColor: '#F5F5F5', // Set background color for input
                                                        color: '#2A3663', // Input text color
                                                        fontSize: '14px', // Input text size (slightly smaller than the label)
                                                        border: '1px solid #3F72AF', // Optional: Border matching the label color
                                                        padding: '10px', // Add some padding for better spacing
                                                        borderRadius: '4px', // Optional: Rounded corners
                                                    }}

                                                >
                                                    <option value="">Select</option>
                                                    {
                                                        relation.map((val) => {
                                                            return (

                                                                <option >{val.value}</option>

                                                            )
                                                        })

                                                    }
                                                </select>
                                            </div>
                                        </div>

                                    </div> : null}
                                            <div style={{ paddingLeft: 20, paddingRight: 20, marginTop: 10 }} >

                                                <h6 style={{ color: 'black', marginTop: 15, textAlign: 'left', fontSize: 16, fontWeight: 'bold' }}>Life Style</h6>
                                            </div>
                                            <div className="row" style={{ paddingLeft: 20, paddingRight: 20, marginTop: 10 }}  >
                                                <div className="col-md-4">
                                                    <div className="input-item input-with-label" style={{ textAlign: 'left' }}>
                                                        <label className="input-item-label text-exlight" style={{ color: '#3F72AF', fontSize: '16px' }}>Smoke: </label>
                                                        <div style={{ width: '100%' }} className="select-wrapper">
                                                            <select
                                                                className="input-bordered"
                                                                style={{
                                                                    backgroundColor: '#F5F5F5', // Set background color for input
                                                                    color: '#2A3663', // Input text color
                                                                    fontSize: '14px', // Input text size (slightly smaller than the label)
                                                                    border: '1px solid #3F72AF', // Optional: Border matching the label color
                                                                    padding: '10px', // Add some padding for better spacing
                                                                    borderRadius: '4px', // Optional: Rounded corners
                                                                }}
                                                                name="tobaco"
                                                                value={tobaco}
                                                                onChange={(event) => tobacoChangeHandler(event)}
                                                            >
                                                                <option value="">Select</option>

                                                                {
                                                                    arraySmoke.map((val) => {
                                                                        return (

                                                                            <option >{val.value}</option>

                                                                        )
                                                                    })

                                                                }
                                                            </select>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-4">
                                                    <div className="input-item input-with-label" style={{ textAlign: 'left' }}>
                                                    <label className="input-item-label text-exlight" style={{ color: '#3F72AF', fontSize: '16px' }}>Alcohol: </label>
                                                        <div style={{ width: '100%' }} className="select-wrapper">
                                                            <select

                                                                className="input-bordered"
                                                                style={{
                                                                    backgroundColor: '#F5F5F5', // Set background color for input
                                                                    color: '#2A3663', // Input text color
                                                                    fontSize: '14px', // Input text size (slightly smaller than the label)
                                                                    border: '1px solid #3F72AF', // Optional: Border matching the label color
                                                                    padding: '10px', // Add some padding for better spacing
                                                                    borderRadius: '4px', // Optional: Rounded corners
                                                                }}
                                                                name="alcohol"
                                                                value={alcohol}
                                                                onChange={(event) => alcoholChangeHandler(event)}

                                                            >
                                                                <option value="">Select</option>

                                                                {
                                                                    arrayAlcohol.map((val) => {
                                                                        return (

                                                                            <option >{val.value}</option>

                                                                        )
                                                                    })

                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row" style={{ paddingLeft: 20, paddingRight: 20 }}>
                                                <div className="col-md-4">
                                                    <div className="input-item input-with-label" style={{ textAlign: 'left' }}>
                                                    <label className="input-item-label text-exlight" style={{ color: '#3F72AF', fontSize: '16px' }}>Exercise: </label>
                                                        <div style={{ width: '100%' }} className="select-wrapper">
                                                            <select

                                                                className="input-bordered"
                                                                style={{
                                                                    backgroundColor: '#F5F5F5', // Set background color for input
                                                                    color: '#2A3663', // Input text color
                                                                    fontSize: '14px', // Input text size (slightly smaller than the label)
                                                                    border: '1px solid #3F72AF', // Optional: Border matching the label color
                                                                    padding: '10px', // Add some padding for better spacing
                                                                    borderRadius: '4px', // Optional: Rounded corners
                                                                }}
                                                                name="exercise"
                                                                value={exercise}
                                                                onChange={(event) => exerciseChangeHandler(event)}

                                                            >
                                                                <option value="">Select</option>

                                                                {
                                                                    arrayExercise.map((val) => {
                                                                        return (

                                                                            <option >{val.value}</option>

                                                                        )
                                                                    })

                                                                }
                                                            </select>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-4">
                                                    <div className="input-item input-with-label" style={{ textAlign: 'left' }}>
                                                        <label className="input-item-label text-exlight" style={{ color: '#3F72AF', fontSize: '16px' }}>Food style: </label>
                                                        <div style={{ width: '100%' }} className="select-wrapper">
                                                            <select

                                                                className="input-bordered"
                                                                style={{
                                                                    backgroundColor: '#F5F5F5', // Set background color for input
                                                                    color: '#2A3663', // Input text color
                                                                    fontSize: '14px', // Input text size (slightly smaller than the label)
                                                                    border: '1px solid #3F72AF', // Optional: Border matching the label color
                                                                    padding: '10px', // Add some padding for better spacing
                                                                    borderRadius: '4px', // Optional: Rounded corners
                                                                }}
                                                                name="diet"
                                                                value={diet}
                                                                onChange={(event) => dietChangeHandler(event)}

                                                            >
                                                                <option value="">Select</option>
                                                                <option value="Veg">Veg</option>
                                                                <option value="NonVeg">NonVeg</option>

                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>







                                            <div style={{ paddingLeft: 15, paddingRight: 15, marginTop: 10 }} >

                                                <h6 style={{ color: 'black', marginTop: 15, textAlign: 'left', fontSize: 16, fontWeight: 'bold', paddingLeft:'5px' }}>Family History *</h6>
                                            </div>
                                            <div className="row" style={{ paddingLeft: 20, paddingRight: 20, marginTop: 10 }}  >

                                            <div className="col-md-4">
                                                    <div className="input-item input-with-label" style={{ textAlign: 'left' }}>
                                                    <label className="input-item-label text-exlight" style={{ color: '#3F72AF', fontSize: '16px' }}>Disease: </label>
                                                     <div className="row" style={{ paddingLeft: 15, paddingRight: 15}}  >

                                                        <div style={{ width: '45%' }} className="select-wrapper">
                                                            <select
                                                                name="disease1"
                                                                value={disease1}
                                                                onChange={(event) => disease1ChangeHandler(event)}

                                                                className="input-bordered"
                                                                style={{
                                                                    backgroundColor: '#F5F5F5', // Set background color for input
                                                                    color: '#2A3663', // Input text color
                                                                    fontSize: '14px', // Input text size (slightly smaller than the label)
                                                                    border: '1px solid #3F72AF', // Optional: Border matching the label color
                                                                    padding: '10px', // Add some padding for better spacing
                                                                    borderRadius: '4px', // Optional: Rounded corners
                                                                }}

                                                            >
                                                                <option value="">Select</option>

                                                                {
                                                                    familyHistoryDisease.map((val) => {
                                                                        return (

                                                                            <option value={val.code}>{val.value}</option>

                                                                        )
                                                                    })

                                                                }
                                                            </select>
                                                        </div>



                                                        <div style={{ width: '45%', marginLeft: 20 }} className="select-wrapper">
                                                            <select
                                                                name="relation1"
                                                                value={relation1}
                                                                onChange={(event) => relation1ChangeHandler(event)}

                                                                className="input-bordered"
                                                                style={{
                                                                    backgroundColor: '#F5F5F5', // Set background color for input
                                                                    color: '#2A3663', // Input text color
                                                                    fontSize: '14px', // Input text size (slightly smaller than the label)
                                                                    border: '1px solid #3F72AF', // Optional: Border matching the label color
                                                                    padding: '10px', // Add some padding for better spacing
                                                                    borderRadius: '4px', // Optional: Rounded corners
                                                                }}

                                                            >
                                                                <option value="">Select</option>

                                                                {
                                                                    relation.map((val) => {
                                                                        return (

                                                                            <option value={val.code}>{val.value}</option>

                                                                        )
                                                                    })

                                                                }
                                                            </select>
                                                        </div>

                                                     </div>
                                                        
                                                    </div>

                                                </div>


                                            </div>


                                            <div className="row" style={{ paddingLeft: 20, paddingRight: 20}}  >

                                            <div className="col-md-4">
                                                <div className="input-item input-with-label" style={{ textAlign: 'left' }}>
                                                    <label className="input-item-label text-exlight" style={{ color: '#3F72AF', fontSize: '16px' }}>Disease: </label>
                                                        <div className="row" style={{ paddingLeft: 15, paddingRight: 15}}>
                                                            <div style={{ width: '45%' }} className="select-wrapper">
                                                                <select
                                                                    name="disease2"
                                                                    value={disease2}
                                                                    onChange={(event) => disease2ChangeHandler(event)}

                                                                    className="input-bordered"
                                                                    style={{
                                                                        backgroundColor: '#F5F5F5', // Set background color for input
                                                                        color: '#2A3663', // Input text color
                                                                        fontSize: '14px', // Input text size (slightly smaller than the label)
                                                                        border: '1px solid #3F72AF', // Optional: Border matching the label color
                                                                        padding: '10px', // Add some padding for better spacing
                                                                        borderRadius: '4px', // Optional: Rounded corners
                                                                    }}

                                                                >
                                                                    <option value="">Select</option>

                                                                    {
                                                                        familyHistoryDisease.map((val) => {
                                                                            return (

                                                                                <option value={val.code} >{val.value}</option>

                                                                            )
                                                                        })

                                                                    }
                                                                </select>
                                                            </div>


                                                            <div style={{ width: '45%', marginLeft: 20 }} className="select-wrapper">
                                                                <select
                                                                    name="relation2"
                                                                    value={relation2}
                                                                    onChange={(event) => relation2ChangeHandler(event)}

                                                                    className="input-bordered"
                                                                    style={{
                                                                        backgroundColor: '#F5F5F5', // Set background color for input
                                                                        color: '#2A3663', // Input text color
                                                                        fontSize: '14px', // Input text size (slightly smaller than the label)
                                                                        border: '1px solid #3F72AF', // Optional: Border matching the label color
                                                                        padding: '10px', // Add some padding for better spacing
                                                                        borderRadius: '4px', // Optional: Rounded corners
                                                                    }}

                                                                >
                                                                    <option value="">Select</option>

                                                                    {
                                                                        relation.map((val) => {
                                                                            return (

                                                                                <option value={val.code}>{val.value}</option>

                                                                            )
                                                                        })

                                                                    }
                                                                </select>
                                                            </div>

                                                        </div> 

                                                    </div>

                                                </div>


                                            </div>


                                            <div style={{ paddingLeft: 20, paddingRight: 20, marginTop: 10 }} >

                                                <h6 style={{ color: 'black', marginTop: 15, textAlign: 'left', fontSize: 16, fontWeight: 'bold' }}>Health Details</h6>
                                            </div>
                                            <div className="row" style={{ paddingLeft: 20, paddingRight: 20, marginTop: 10 }}  >

                                                <div className="col-md-6">
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                                                        <p style={{ textAlign: 'left' }} className="label_Health">Allergies </p>
                                                        <div style={{ align: 'right' }}>
                                                            <input type="radio"

                                                                name="allergies"
                                                                value={1}
                                                                onChange={(event) => allergyChangeHandler(event)}


                                                            /> Yes
                                                            <input style={{ marginLeft: 10 }} type="radio"


                                                                name="allergies"
                                                                value={0}
                                                                onChange={(event) => allergyChangeHandler(event)}

                                                            /> No
                                                        </div>

                                                    </div>
                                                </div>

                                                {allergies == '1' ?

                                                    <div className="col-md-6">
                                                        <div style={{ textAlign: 'left' }}>

                                                            <input
                                                                type="text"
                                                                className="input-bordered"
                                                                name="allergiesInfo"
                                                                value={allergiesInfo}
                                                                onChange={(event) => allergiesInfoChangeHandler(event)}
                                                                style={{
                                                                    backgroundColor: '#F5F5F5', // Set background color for input
                                                                    color: '#2A3663', // Input text color
                                                                    fontSize: '14px', // Input text size (slightly smaller than the label)
                                                                    border: '1px solid #3F72AF', // Optional: Border matching the label color
                                                                    padding: '10px', // Add some padding for better spacing
                                                                    borderRadius: '4px', // Optional: Rounded corners
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    : null

                                                }


                                            </div>


                                            <div className="row" style={{ paddingLeft: 20, paddingRight: 20, marginTop: 10 }}  >

                                                <div className="col-md-6">

                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                                                        <p style={{ textAlign: 'left' }} className="label_Health">HIV/AIDS </p>
                                                        <div style={{ align: 'right' }}>
                                                            <input type="radio"
                                                                value={1}
                                                                onChange={(event) => hivChangeHandler(event)} name="hiv"
                                                            /> Yes
                                                            <input style={{ marginLeft: 10 }} type="radio"

                                                                value={0}
                                                                onChange={(event) => hivChangeHandler(event)} name="hiv"

                                                            /> No
                                                        </div>

                                                    </div>
                                                </div>
                                                {hiv == '1' ?

                                                    <div className="col-md-6">
                                                        <div style={{ textAlign: 'left' }}>

                                                            <input
                                                                type="text"
                                                                className="input-bordered"
                                                                name="hivInfo"
                                                                value={hivInfo}
                                                                onChange={(event) => hivInfoChangeHandler(event)}
                                                                style={{
                                                                    backgroundColor: '#F5F5F5', // Set background color for input
                                                                    color: '#2A3663', // Input text color
                                                                    fontSize: '14px', // Input text size (slightly smaller than the label)
                                                                    border: '1px solid #3F72AF', // Optional: Border matching the label color
                                                                    padding: '10px', // Add some padding for better spacing
                                                                    borderRadius: '4px', // Optional: Rounded corners
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    : null

                                                }

                                            </div>
                                            <div className="row" style={{ paddingLeft: 20, paddingRight: 20, marginTop: 10 }} >
                                                <div className="col-md-6">

                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                                                        <p style={{ textAlign: 'left' }} className="label_Health">Diabetes </p>
                                                        <div style={{ align: 'right' }}>
                                                            <input type="radio"

                                                                value={1}
                                                                onChange={(event) => diabetesChangeHandler(event)} name="diabetes"


                                                            /> Yes
                                                            <input style={{ marginLeft: 10 }} type="radio"
                                                                value={0}
                                                                onChange={(event) => diabetesChangeHandler(event)} name="diabetes"


                                                            /> No
                                                        </div>
                                                    </div>
                                                </div>
                                                {diabetes == '1' ?

                                                    <div className="col-md-6">
                                                        <div style={{ textAlign: 'left' }}>

                                                            <input
                                                                type="text"
                                                                className="input-bordered"
                                                                name="diabetesInfo"
                                                                value={diabetesInfo}
                                                                onChange={(event) => diabetesInfoChangeHandler(event)}
                                                                style={{
                                                                    backgroundColor: '#F5F5F5', // Set background color for input
                                                                    color: '#2A3663', // Input text color
                                                                    fontSize: '14px', // Input text size (slightly smaller than the label)
                                                                    border: '1px solid #3F72AF', // Optional: Border matching the label color
                                                                    padding: '10px', // Add some padding for better spacing
                                                                    borderRadius: '4px', // Optional: Rounded corners
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    : null

                                                }


                                            </div>



                                            <div style={{ paddingLeft: 20, paddingRight: 20, marginTop: 20 }} className="row">
                                                <div className="col-md-6">
                                                    <div className="input-item input-with-label" style={{ textAlign: 'left' }}>
                                                        <p className="label_Health" style={{ textAlign: 'left' }}>List of medication you currently take: </p>
                                                        <input
                                                            type="text"
                                                            className="input-bordered"
                                                            name="medication"
                                                            value={medication}
                                                            onChange={(event) => medicationChangeHandler(event)}
                                                            style={{
                                                                backgroundColor: '#F5F5F5', // Set background color for input
                                                                color: '#2A3663', // Input text color
                                                                fontSize: '14px', // Input text size (slightly smaller than the label)
                                                                border: '1px solid #3F72AF', // Optional: Border matching the label color
                                                                padding: '10px', // Add some padding for better spacing
                                                                borderRadius: '4px', // Optional: Rounded corners
                                                            }}
                                                        />
                                                    </div>

                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-item input-with-label" style={{ textAlign: 'left' }}>
                                                        <p className="label_Health" style={{ textAlign: 'left' }}>  Any other disease or issue :</p>
                                                        <input
                                                            type="text"
                                                            className="input-bordered"
                                                            name="otherIssue"
                                                            value={otherDisease}
                                                            onChange={(event) => otherDiseaseChangeHandler(event)}
                                                            style={{
                                                                backgroundColor: '#F5F5F5', // Set background color for input
                                                                color: '#2A3663', // Input text color
                                                                fontSize: '14px', // Input text size (slightly smaller than the label)
                                                                border: '1px solid #3F72AF', // Optional: Border matching the label color
                                                                padding: '10px', // Add some padding for better spacing
                                                                borderRadius: '4px', // Optional: Rounded corners
                                                            }}
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ marginTop: 10, marginLeft: 'auto', marginRight: 'auto' }}>

                                                {msg ? <p style={{ fontSize: 13, fontWeight: 'bold', color: 'red' }}>{msg}</p> : null}
                                            </div>

                                            <div style={{ marginTop: 20 }}>
                                                <form method="post"
                                                    onSubmit={(event) => patientDataSaveHandler(event)}
                                                >
                                                    {
                                                        buttonHide == false ?
                                                            <button type="submit" className="btn btn-auto btn-success">
                                                                Submit
                                                            </button>
                                                            :
                                                            <p style={{ fontSize: 14, fontWeight: 'bold', color: 'green' }}>Submitting...</p>
                                                    }
                                                </form>

                                            </div>
                                        </>
                                        : null
                                }


                            </div>

                            :
                            <>
                                <FormSubmitPopup name={patientBasicInfo} />

                            </>

                        }


                    </>
                    :
                    <div style={{ marginTop: 20, marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#FFFAFA', height: 100, width: 'auto', padding: 20, marginTop: 50 }}>
                        <p style={{ fontSize: 14, fontWeight: 'bold' }}>Your New Patient form for {clinicName} has already been submitted.</p>
                    </div>
                }



            </div >

        </>
    )

}