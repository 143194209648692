import React, { useState, useEffect, useRef, useCallback } from "react";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import back from "../assets/images/back.png";
import { useRecoilState } from "recoil";
import { patient_profile_image_show } from "../Store/PatientProfileDetails";
import { BiFolderPlus } from "react-icons/bi";
import imageCompression from "browser-image-compression";
import Webcam from "react-webcam";
import camera from "../assets/images/camera.png";
import cameraSwitch from "../assets/images/cameraSwitch.png";
import upload from "../assets/images/upload1.png";
import folder from "../assets/images/folder1.png";
import { Camera, CameraResultType } from "@capacitor/camera";
import { CameraSource } from "@capacitor/camera";
import { message } from 'antd'

export default () => {
  const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState("");
  const [isSelectedImage, setIsSelectedImage] = useState(false);
  const [msg, setMsg] = useState("");
  const [buttonHide, setButtonHide] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [profileImgShow, setProfileImgShow] = useRecoilState(
    patient_profile_image_show
  );
  let patientUniqueId = localStorage.getItem("patientUniqueId");

  const uploadPhotoHandler = (e) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    setButtonHide(true);
    e.preventDefault();
    let formdata = $(e.target);
    formdata = new FormData();
    formdata.append("file", imgData);
    formdata.append("patientid", patientUniqueId);
    formdata.append("flag", "P"); //P for profile
    $.ajax({
      type: "POST",
      url: `${process.env.REACT_APP_GLOBAL_BASEURL}/api/commonapi/FileUpload/file_insert_patientapp`,
      data: formdata,
      processData: false,
      contentType: false,
      success(uploadedimage) {
        if (uploadedimage) {
          formdata.append("profile_image", uploadedimage);
          formdata.append("patientid", patientUniqueId);
          console.log("uploadedimage: ", uploadedimage);
          console.log("patientid: ", patientUniqueId);
          $.ajax({
            type: "POST",
            url: `${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientBioProfile/save_patient_profile`,
            data: formdata,
            processData: false,
            contentType: false,
            headers: headers,
            success(data) {
              console.log(data);
              if (data) {
                setButtonHide(false);
                setMsg("Updated successfully");
                setProfileImgShow(true);
                setTimeout(() => {
                  navigate("/");
                }, 2000);
              }
            },
            error: function (err) {
              setButtonHide(false);
              if (err.status === 401) {
                // Token expired, show message and log out
                message.error(err.responseJSON.message);
                logoutHandler();
              } else {
                console.error('Error occurred:', err.responseText);
                alert("Error has occurred: ", err.responseText);
              }
            },
          });
        }
      },
      error: function (err) {
        setButtonHide(false);
        console.error('Error occurred:', err.responseText);
        alert("Error has occurred: ", err.responseText);
      },
    });
  };

  const logoutHandler = () => {
    localStorage.removeItem("phoneNo");
    localStorage.removeItem("patientid");
    localStorage.removeItem("patientLocalImage");
    localStorage.removeItem("patientLocalData");
    localStorage.removeItem("patientUniqueId");
    localStorage.removeItem("chronic_illness_data");
    localStorage.removeItem("allergic_information_data");
    localStorage.removeItem("family_relation_data");
    localStorage.removeItem("family_disease_data");
    localStorage.removeItem("token");

    navigate("/Login");

  }

  const fileInputRef = useRef();
  const triggerUpload = () => {
    fileInputRef.current.click();
  };

  const fileChangeHandler = async (event) => {
    console.log("fileChangeHandler: selected file", event.target.files[0]);

    // Compression options
    const options1 = {
      maxSizeMB: 0.2,
      useWebWorker: true,
    };

    try {
      let base64Data;
      let file = event.target.files[0];

      // Check if the file size exceeds the threshold
      if (file.size > 512000) {
        const compressedFile = await imageCompression(file, options1);
        console.log("Compressed file:", compressedFile);

        // Convert compressed file to base64
        const compressedBlob = await fetch(URL.createObjectURL(compressedFile)).then((res) =>
          res.blob()
        );
        base64Data = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result.split(",")[1]);
          reader.readAsDataURL(compressedBlob);
        });

        // Update file reference with the compressed file
        file = compressedFile;
      } else {
        // Convert original file to base64
        base64Data = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result.split(",")[1]);
          reader.readAsDataURL(file);
        });
      }

      // Update states
      const url = URL.createObjectURL(file);
      console.log("URL:", url);
      setPicture(url);
      setImgData(base64Data);
      console.log("base64Data: ", base64Data);
      setIsSelectedImage(true);
    } catch (error) {
      console.error("Error in fileChangeHandler:", error);
    }
  };

  const startCam = async () => {
    try {
      // Capture photo with specified settings
      const image = await Camera.getPhoto({
        quality: 100,
        allowEditing: true,
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Prompt,
      });

      // Compression options
      const options1 = {
        maxSizeMB: 0.3,
        maxWidthOrHeight: 1000,
        useWebWorker: true,
      };

      let url = image.dataUrl;
      setPicture(url);

      // Convert data URL to blob and compress it
      const blob = await (await fetch(url)).blob();
      console.log("Original file:", blob);
      await compress(blob, options1, url);
    } catch (error) {
      console.error("Error starting camera:", error);
    }
  };

  const compress = async (blob, options1, url) => {
    try {
      let base64Data = url.split(',')[1]; // Extract base64 data and remove prefix

      if (blob.size > 512000) {
        const compressedFile = await imageCompression(blob, options1);
        console.log("Compressed file:", compressedFile);

        // Create a new URL for the compressed file
        url = URL.createObjectURL(compressedFile);

        // Fetch the compressed file to get its data URL
        const compressedBlob = await fetch(url).then((res) => res.blob());
        const compressedBase64Data = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result.split(',')[1]);
          reader.readAsDataURL(compressedBlob);
        });

        base64Data = compressedBase64Data; // Update the base64Data with compressed data
        setPicture(url);
        setImgData(base64Data);
      } else {
        console.log("Camera: selected blob:", blob);
        setPicture(url);
        setImgData(base64Data);
      }
      console.log("base64Data: ", base64Data);
      setIsSelectedImage(true);
    } catch (error) {
      console.error("Error compressing file:", error);
    }
  };

  return (
    <div className="page-user">
      <img
        src={back}
        style={{ height: 20, width: 20, marginTop: 20, marginLeft: 20 }}
        onClick={() => navigate("/EditProfile")}
      />

      <div style={{ marginTop: 5 }} className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4"></div>
            <div className="col-xl-4 col-lg-4">
              <h6 className="card-title">Edit Photo</h6>
            </div>
            <div className="col-xl-4 col-lg-4"></div>
          </div>
        </div>
      </div>

      <div className="page-content">
        <div className="container">
          <div style={{ marginTop: -10 }} className="row">
            <div className="col-xl-12 col-lg-12">
              <div
                style={{
                  padding: 10,
                  height: 550,
                  backgroundColor: "white",
                  marginTop: 30,
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <img
                    style={{
                      height: 400,
                      width: 600,
                      marginLeft: "auto",
                      marginRight: "auto",
                      border: "0.1px solid blue",
                    }}
                    src={picture}
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div onClick={startCam} style={{ width: "30%" }}>
                    <img
                      src={camera}
                      style={{ height: 40, width: 40, marginTop: 15 }}
                    />
                    <p
                      style={{ fontSize: 13, fontWeight: "600", marginTop: 16 }}
                    >
                      Camera
                    </p>
                  </div>
                  <input
                    className="fileinputstyle"
                    style={{ marginTop: 15, width: "25%" }}
                    type="file"
                    name="mfile"
                    id="mfile"
                    ref={fileInputRef}
                    onChange={(event) => fileChangeHandler(event)}
                  />

                  <img
                    style={{
                      marginTop: 15,
                      height: 39,
                      marginLeft: -65,
                      width: 40,
                    }}
                    onClick={triggerUpload}
                    src={folder}
                  />

                  {isSelectedImage ? (
                    <>
                      <div
                        style={{
                          textAlign: "left",
                          marginLeft: 85,
                          marginTop: -60,
                        }}
                      >
                        <form method="post">
                          {buttonHide == false ? (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={(event) => uploadPhotoHandler(event)}
                            >
                              <img
                                src={upload}
                                style={{
                                  marginTop: 75,
                                  height: 45,
                                  width: 45,
                                  cursor: "pointer",
                                }}
                              />
                              <p
                                style={{
                                  fontSize: 13,
                                  fontWeight: "600",
                                  marginTop: 10,
                                  marginLeft: 3,
                                }}
                              >
                                Upload
                              </p>
                            </div>
                          ) : (
                            <p
                              style={{
                                color: "green",
                                fontSize: 13,
                                marginTop: 75,
                              }}
                            >
                              Uploading...
                            </p>
                          )}
                        </form>
                      </div>
                    </>
                  ) : null}
                </div>
                <p
                  style={{
                    fontSize: 13,
                    fontWeight: "600",
                    marginTop: -22,
                    marginLeft: -62,
                  }}
                >
                  My files
                </p>
                {msg ? (
                  <p
                    style={{
                      color: "green",
                      fontSize: 13,
                      fontWeight: "bold",
                      marginTop: 25,
                    }}
                  >
                    {msg}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
