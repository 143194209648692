import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import back from '../assets/images/back.png';
import Axios from 'axios';
import { useLocation } from 'react-router';
import $ from "jquery";
import { message } from 'antd'

const moment = require('moment');
const ViewTicket = () => {
    const [ticketDetails, setTicketDetails] = useState([]);
    const [isOpenInput, setIsOpenInput] = useState(false);
    const [feedback, setFeedback] = useState("");
    const [feedbackMsg, setFeedbackMsg] = useState("");
    const [buttonHide, setButtonHide] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const navigate = useNavigate();
    const backHandler = () => {
        global.OTPValidation = 'true';
        navigate("/Tickets");
    }
    const location = useLocation();
    console.log("state: ", location.state)
    let ticket_number = location.state.ticket_number
    useEffect(() => {
        const fetchTickets = async () => {
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`,
            };

            try {
                const response = await Axios.get(
                    `${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientsSupport/get_ticket_by_ticketno?ticket_number=${ticket_number}`,
                    { headers }
                );

                if (response.data.status == '1') {
                    setTicketDetails(response.data.response);
                } else if (response.data.status == '401') {
                    logoutHandler();
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    console.error('Unauthorized access. Logging out.');
                    logoutHandler();
                } else {
                    console.error('Error:', error.message);
                }
            }
        }
        fetchTickets();
    }, [])
    let feddback = [
        {
            id: 1,
            key: '1',
            value: 'Problem Solved',

        },
        {
            id: 2,
            key: '0',
            value: 'Need more advice',

        },
    ]
    const onFeddbackChange = (e, key) => {
        console.log(e.target.value);
        setFeedback(key);
        if (key == "0") {
            setIsOpenInput(true);
        }
    }
    const onFeedbackMsg = (e) => {
        setFeedbackMsg(e.target.value);
    }
    const postHandler = (e) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        setButtonHide(true);
        e.preventDefault();
        console.log("fgg")
        setErrMsg("");

        let formdata = $(e.target);
        formdata = new FormData();
        formdata.append('ticket_number', ticket_number);
        formdata.append('feedback', feedback);
        formdata.append('feedbackMessage', feedbackMsg);

        $.ajax({
            type: "POST",
            url: `${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientsSupport/update_feedback`,
            data: formdata,
            processData: false,
            contentType: false,
            headers: headers,
            success(data) {
                console.log("post successfully", data);
                setTimeout(navigate("/Tickets"), 2000);
            },
            error(err) {
                if (err.status === 401) {
                    // Token expired, show message and log out
                    message.error(err.responseJSON.message);
                    logoutHandler();
                } else {
                    console.error('Error occurred:', err.responseText);
                    alert("Error has occurred: ", err.responseText);
                }
            }
        });

    }

    const logoutHandler = () => {
        localStorage.removeItem("phoneNo");
        localStorage.removeItem("patientid");
        localStorage.removeItem("patientLocalImage");
        localStorage.removeItem("patientLocalData");
        localStorage.removeItem("patientUniqueId");
        localStorage.removeItem("chronic_illness_data");
        localStorage.removeItem("allergic_information_data");
        localStorage.removeItem("family_relation_data");
        localStorage.removeItem("family_disease_data");
        localStorage.removeItem("token");

        navigate("/Login");

    }

    return (
        <div>
            <div style={{ textAlign: 'left' }}>

                <img src={back} style={{ height: 20, width: 20, marginTop: 15, marginLeft: 20 }} onClick={backHandler} />
            </div>

            <p style={{ fontSize: 20, fontWeight: 'bold', color: '#A696FF', marginTop: -10 }}>View Ticket Details</p>

            <div className='first-div'>
                {ticketDetails.map((details) => {
                    return (
                        <>

                            <div style={{ marginLeft: 15, display: "flex", flexDirection: "row" }} >
                                <div style={{ width: '50%', textAlign: 'left' }}>
                                    <p className="label_style"> Ticket No :</p>
                                </div>
                                <div style={{ width: '23%', textAlign: 'left' }}>
                                    <p> {details.ticket_number}</p>
                                </div>
                            </div>
                            <div style={{ marginLeft: 15, display: "flex", flexDirection: "row" }} >
                                <div style={{ width: '50%', textAlign: 'left' }}>
                                    <p className="label_style"> Date :</p>
                                </div>
                                <div style={{ width: '50%', textAlign: 'left' }}>
                                    <p> {moment(details.create_datetime).format('DD.MM.YYYY')}</p>
                                </div>
                            </div>
                            <div style={{ marginLeft: 15, display: "flex", flexDirection: "row" }} >
                                <div style={{ width: '50%', textAlign: 'left' }}>
                                    <p className="label_style"> Status :</p>
                                </div>
                                <div style={{ width: '23%', textAlign: 'left' }}>
                                    <p>{details.status == "0" ? "Submitted" : null}
                                        {details.status == "1" ? "Processing" : null}
                                        {details.status == "2" ? "Replied" : null}
                                        {details.status == "3" ? "Solved" : null}
                                        {details.status == "4" ? "Rejected" : null}
                                    </p>
                                </div>
                            </div>
                            <div style={{ marginLeft: 15, display: "flex", flexDirection: "row" }}>
                                <div style={{ width: '95%', textAlign: 'left' }}>
                                    <p className="label_style"> Problem Statement:</p>
                                    <p style={{ marginLeft: 10 }}>{details.problem_details}</p>
                                </div>
                            </div>
                            <div style={{ marginLeft: 15, display: "flex", flexDirection: "row" }} >
                                <div style={{ width: '95%', textAlign: 'left' }}>
                                    <p className="label_style"> {details.problem_response != null ? "Reply :" : null}</p>
                                    <p style={{ marginLeft: 10 }}>{details.problem_response}</p>
                                </div>
                            </div>
                            <div style={{ marginLeft: 15, display: "flex", flexDirection: "row" }} >
                                <div style={{ width: '95%', textAlign: 'left' }}>
                                    <p className="label_style"> {details.status == "2" ? "Feedback :" : null}</p>
                                    {details.status == "2" ?
                                        feddback.map((val) => {
                                            return (

                                                <>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }} >
                                                        <div style={{ width: '25%', textAlign: 'left', marginLeft: -30 }}>
                                                            <input
                                                                type="radio"
                                                                style={{ width: 100 }}
                                                                value={val.value}
                                                                name="feedback"
                                                                onChange={(event) => onFeddbackChange(event, val.key)}
                                                            />
                                                        </div>
                                                        <div style={{ width: '75%', textAlign: 'left' }}>
                                                            <p>{val.value}</p>
                                                        </div>

                                                    </div>
                                                </>

                                            );
                                        })
                                        : null}
                                </div>
                            </div>
                            {isOpenInput ?

                                <textarea class="form-control"
                                    style={{ width: '80%', marginLeft: 25, marginTop: 20 }}
                                    id="feedbackMsg"
                                    name="feedbackMsg"
                                    rows="5"
                                    value={feedbackMsg}
                                    onChange={(event) => onFeedbackMsg(event)}
                                    placeholder='add message here'
                                >

                                </textarea> : null
                            }

                            {details.status == "2" ?
                                <form method="post"
                                    onSubmit={(event) => postHandler(event)}
                                >
                                    {
                                        buttonHide == false ?
                                            <button type="submit" style={{ marginTop: 30, width: 100 }} className="btn btn-auto btn-primary">
                                                Post
                                            </button>
                                            :
                                            <p style={{ fontSize: 14, color: 'green', fontWeight: 'bold', marginTop: 15, marginLeft: 50 }}>Sending...</p>

                                    }


                                </form> : null}
                        </>
                    )
                })}
            </div>

        </div>
    )
}

export default ViewTicket
