import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router';
import back from '../assets/images/back.png';
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import Axios from 'axios';
import { message } from 'antd'

const OthersMedicalVitals = () => {
    const [vitalsTrackingType, setVitalsTrackingType] = useState([]);
    const [readingValue, setReadingValue] = useState("");
    const [buttonHide, setButtonHide] = useState(false);
    const today = new Date();
    const numberOfDaysToAdd = 0;
    const date1 = today.setDate(today.getDate() + numberOfDaysToAdd);
    const defaultValue = new Date(date1).toISOString().split('T')[0] // yyyy-mm-dd
    const [date, setDate] = useState(defaultValue);
    var hour = today.getHours();
    var min = today.getMinutes();
    hour = (hour < 10 ? "0" : "") + hour;
    min = (min < 10 ? "0" : "") + min;
    var displayTime = hour + ":" + min;
    const [time, setTime] = useState(displayTime);
    const [vitalsOnSelectTrackingType, setVitalsOnSelectTrackingType] = useState("");
    const [tableData, setTableData] = useState([]);
    const [trackingId, setTrackingId] = useState('');
    const [trackingType, setTrackingType] = useState('');
    let pid = localStorage.getItem("patientid");
    const [msg, setMsg] = useState(false);
    const [ohersValue, setOthersValue] = useState("");
    const [isOpenInput, setIsOpenInput] = useState(false);

    const navigate = useNavigate();
    //const location = useLocation();
    const backHandler = () => {
        global.OTPValidation = 'true';
        navigate("/");
    }

    useEffect(() => {
        const getMasterTrackingType = async () => {
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`, // Add the token to the request headers
            };

            try {
                const response = await Axios.get(
                    `${process.env.REACT_APP_GLOBAL_BASEURL}/api/commonapi/PickList/get_master_tracking_type`,
                    { headers }
                );
                if (response.data.status == '1') {
                    setVitalsTrackingType(response.data.response)
                    console.log("master tracking type :", response.data)
                }
                setIsOpenInput(false);
                setMsg(false)
                //setTrackingType(tracking_type)
                // setTrackingId(id)
                // setVitalsOnSelectTrackingType(e.target.value)
                // console.log(e.target.value)
                console.log("patientId=", pid);
                // Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientHealthTracking/get_medical_tracking_data_by_trackingid?trackingid=${response.data.response.id}&patientid=${pid}`)

                //     .then((response) => {

                //         if (response.data.status == '1') {
                //             console.log(response.data)
                //             setTableData(response.data.response);
                //             setMsg(false);
                //         }
                //         else {
                //             setTableData([]);
                //             setMsg(true);
                //         }
                //     });
            } catch (error) {
                // Display error message based on the response or fallback to a default message
                if (error.response && error.response.status === 401) {
                    console.error("Unauthorized access. Logging out.");
                    message.error(error.response.data?.message || 'Unauthorized');
                    logoutHandler(); // Ensure logout happens here for unauthorized access
                } else {
                    console.error("Error:", error.message);
                    message.error('An error occurred. Please try again.');
                }

            }
        }
        getMasterTrackingType();

    }, []);

    const logoutHandler = () => {
        localStorage.removeItem("phoneNo");
        localStorage.removeItem("patientid");
        localStorage.removeItem("patientLocalImage");
        localStorage.removeItem("patientLocalData");
        localStorage.removeItem("patientUniqueId");
        localStorage.removeItem("chronic_illness_data");
        localStorage.removeItem("allergic_information_data");
        localStorage.removeItem("family_relation_data");
        localStorage.removeItem("family_disease_data");
        localStorage.removeItem("token");

        navigate("/Login");

    }

    const onValUpdate = (id, tracking_type) => {
        setIsOpenInput(false);
        setMsg(false)
        setTrackingType(tracking_type)
        setTrackingId(id)
        console.log("patientId=", pid);
        // Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientHealthTracking/get_medical_tracking_data_by_trackingid?trackingid=${id}&patientid=${pid}`)

        //     .then((response) => {

        //         if (response.data.status == '1') {
        //             console.log(response.data)
        //             setTableData(response.data.response);
        //             setMsg(false);
        //         }
        //         else {
        //             setTableData([]);
        //             setMsg(true);
        //         }



        //     });

    }
    const onOthersValUpdate = (event) => {
        setIsOpenInput(true);
        setVitalsOnSelectTrackingType("");
    }

    const onOthersValueHandeler = (e) => {
        console.log(e.target.value);
        setOthersValue(e.target.value);
    }

    return (
        <div>
            <div style={{ textAlign: 'left' }}>
                <img src={back} style={{ height: 20, width: 20, marginTop: 20, marginLeft: 20 }} onClick={backHandler} />
            </div>
            <p style={{ fontSize: 20, marginTop: -10, fontWeight: 'bold', color: '#A696FF' }}> Medical Tracking</p>

            <div className='first-div'>
                <p className="label_style" style={{ textAlign: 'left' }}>Select Medical Tracking</p>
                {vitalsTrackingType.map((val) => {
                    return (

                        <>
                            <div style={{ display: 'flex', flexDirection: 'row' }} onClick={() => navigate("/editVitals", { state: { orginalValue: val } })}>
                                <div style={{ width: '25%', textAlign: 'left', marginLeft: -30 }}>
                                    <input
                                        type="radio"
                                        name={vitalsOnSelectTrackingType}
                                        value={val.tracking_id}
                                        style={{ width: 100 }}
                                        onChange={(event) => onValUpdate(val.tracking_id, val.tracking_type)}
                                    />
                                </div>
                                <div style={{ width: '75%', textAlign: 'left' }}>
                                    <p>{val.tracking_type}</p>
                                </div>
                            </div>
                        </>

                    );
                })}
                <div style={{ display: 'flex', flexDirection: 'row' }} >
                    <div style={{ width: '25%', textAlign: 'left', marginLeft: -30 }}>
                        <input
                            type="radio"
                            name={vitalsOnSelectTrackingType}
                            //name="others"
                            // value={ohersValue}
                            style={{ width: 100 }}
                            onChange={(event) => onOthersValUpdate(event)}

                        />
                    </div>
                    <div style={{ width: '75%', textAlign: 'left' }}>
                        <p>Others</p>
                    </div>
                </div>
                {
                    isOpenInput == true ?
                        <>
                            <div  >
                                <input
                                    className="input_style"
                                    style={{ width: '90%', marginTop: 20 }}
                                    type="text"
                                    name="name"
                                    required
                                    value={ohersValue}
                                    onChange={(e) => onOthersValueHandeler(e)}
                                    placeholder='add tracking name here'
                                    maxLength={30}
                                />



                                <form method="post"
                                    //onSubmit={(event) => othersPostHandler(event)}
                                    onClick={() => navigate("/customEditVitals", { state: { tracking_name: ohersValue } })}
                                >
                                    {
                                        buttonHide == false ?
                                            <button type="submit" style={{ marginTop: 20, width: 70, marginLeft: 15 }} className="btn btn-auto btn-primary">
                                                Set
                                            </button>
                                            :
                                            <p style={{ fontSize: 14, color: 'green', fontWeight: 'bold', marginTop: 15, marginLeft: 50 }}>Sending...</p>

                                    }


                                </form>
                            </div>

                        </> : null
                }

            </div>

        </div>
    )
}

export default OthersMedicalVitals
