import React, { useState, useEffect } from 'react';
import back from '../assets/images/back.png'
import { useNavigate } from "react-router-dom";
import Axios from 'axios';
import '../App.css';
import { AiOutlineDownload, AiFillFilePdf } from "react-icons/ai";
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import Spinner from '../components/Spinner';
import { message } from 'antd'

export default () => {
    const navigate = useNavigate();
    const [consultDetails, setConsultDetails] = useState([]);
    const [msg, setMsg] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPatientDetails = async () => {
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`, // Add the token to the request headers
            };
    
            const id = localStorage.getItem("patientid");
    
            setLoading(true); // Start the loading state
    
            try {
                const response = await Axios.get(
                    `${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientConsultationHistory/get_details?patientid=${id}`,
                    { headers }
                );
    
                console.log("Consultation Details response", response.data);
    
                if (response.data.status === '1') {
                    if (response.data.response.length > 0) {
                        setConsultDetails(response.data.response);
                    } else {
                        setMsg("No history found");
                    }
                } else if (response.data.status === '0') {
                    logoutHandler();
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    console.error("Unauthorized access. Logging out.");
                    message.error(error.response.data?.message || 'Unauthorized');
                    logoutHandler();
                } else {
                    console.error("Error:", error.message);
                    message.error('An error occurred. Please try again.');
                }
            } finally {
                setLoading(false); // Ensure loading state is stopped in all cases
            }
        };
    
        fetchPatientDetails();
    }, []);
    

    const logoutHandler = () => {
        localStorage.removeItem("phoneNo");
        localStorage.removeItem("patientid");
        localStorage.removeItem("patientLocalImage");
        localStorage.removeItem("patientLocalData");
        localStorage.removeItem("patientUniqueId");
        localStorage.removeItem("chronic_illness_data");
        localStorage.removeItem("allergic_information_data");
        localStorage.removeItem("family_relation_data");
        localStorage.removeItem("family_disease_data");
        localStorage.removeItem("token");

        navigate("/Login");

    }

    const backHandler = () => {
        global.OTPValidation = 'true';
        navigate("/");
    }
    const viewDetailsHandler = (constid) => {

        const url = `ConsultationHistoryDetails?text=${base64_encode(constid)}`;
        window.open(url);
        // navigate("/ViewPrescription", { state: constid });

    }
    return (
        <div>
            {
                loading ? <Spinner /> :
                    <>
                        <div style={{ textAlign: 'left' }}>
                            <img src={back} style={{ height: 20, width: 20, marginTop: 20, marginLeft: 20 }} onClick={backHandler} />
                        </div>
                        <p style={{ fontSize: 20, marginTop: -10, fontWeight: 'bold', color: '#A696FF' }}>Consultation History </p>
                        <div style={{ display: 'flex', flexDirection: 'column', width: 'auto', paddingBottom: 20, marginTop: 20 }}>
                            {
                                consultDetails.map((details) => {
                                    return (
                                        <>
                                            {/* <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: 5, height: 'auto', paddingLeft: 10, paddingRight: 10 }}>

                                    <div style={{ width: '100%', marginTop: 10 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <p style={{ fontSize: 14, fontWeight: '600', marginLeft: 5 }}>{details.date_time}</p>
                                            <p style={{ fontSize: 14, fontWeight: 'bold', marginLeft: 10 }}>{details.visiting_purpose}</p>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <button
                                                onClick={() => {
                                                    viewDetailsHandler(details.id)
                                                }}
                                                style={{ marginLeft: 5 }}

                                                className="view-details">View </button>
                                            <p style={{ fontSize: 13, fontWeight: 'bold', marginLeft: 10, marginTop: 5 }}> {details.doctors_name}</p>
                                        </div>
                                    </div>
                                  
                                </div> */}
                                            <div className="medical-records-cards" style={{ height: 70 }}
                                            >
                                                <div key={details.file} style={{ display: 'flex', flexDirection: 'row', marginTop: 5, marginLeft: 10, }} >
                                                    <div style={{ width: 320 }}>
                                                        <div style={{ textAlign: 'left' }}  >
                                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                <p style={{ marginTop: -10, fontSize: 14, fontWeight: 'bold', marginLeft: 15 }}>{details.doctors_name}</p>

                                                            </div>
                                                        </div>
                                                        <div style={{ textAlign: 'right' }}>
                                                            <p style={{ marginTop: -25, fontSize: 14, fontWeight: '600', marginLeft: 10, marginRight: '7%' }}>{details.date_time}</p>


                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <div style={{ textAlign: 'right', width: '70%' }}>
                                                                <p style={{ textAlign: 'left', marginLeft: 16, fontSize: 14 }}>{details.visiting_purpose}</p>
                                                            </div>
                                                            <div style={{ width: '30%', marginTop: -8 }}>
                                                                <AiFillFilePdf className="doc-image" style={{ borderRadius: 10, marginTop: 10, marginBottom: 10, marginLeft: 5 }} size={35} onClick={() => {
                                                                    viewDetailsHandler(details.id)
                                                                }} />



                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>



                                            </div>
                                        </>

                                    )
                                })
                            }

                            {msg ? <p style={{ marginTop: 20, marginLeft: 'auto', marginRight: 'auto', color: 'red', fontSize: 14, fontWeight: 'bold' }}>{msg}</p> : null}
                        </div>
                    </>}
        </div>
    )
}