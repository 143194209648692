import React, { useState, useEffect } from 'react';
import '../App.css';
import Axios from 'axios';
import heading from '../assets/images/heading.png';
import user from '../assets/images/user.png';
import body_temp from '../assets/images/body_temp.png';
import heart_rate from '../assets/images/heart_rate.png';
import weight_icon from '../assets/images/weight.png';
import blood_sugar from '../assets/images/blood_sugar.png';
import blood_pressure from '../assets/images/blood_pressure.png';
import { useNavigate } from "react-router-dom";
import healthprofile from '../assets/images/healthprofile.jpg';
import consulthistory from '../assets/images/consulthistory.jpg';
import doctorsicon from '../assets/images/doctorsicon.png';
import medicalrecords from '../assets/images/medicalrecords.png';
import contact from '../assets/images/contactLogo.png';
import editicon from '../assets/images/editicon.png';
import { useRecoilState } from 'recoil';
import { message } from 'antd'
import {
    is_patient_profile_data_updated, patient_profile_image, patient_profile_image_show,
    patient_medical_data_loading, patient_medical_tracking_details, is_patient_custom_medical_data_updated
} from '../Store/PatientProfileDetails'
import LandingPage from './LandingPage';
import poweroff from '../assets/images/poweroff.jpg'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { FcPlus } from "react-icons/fc";
const moment = require('moment');


export default () => {

    const [phoneNo, setPhoneNo] = useState("");
    const [patientDetails, setPatientDetails] = useState([]);
    const [medicaltrackingData, setMedicaltrackingData] = useRecoilState(patient_medical_tracking_details);
    const [customMedicaltrackingData, setCustomMedicaltrackingData] = useState([]);
    const [profileImg, setProfileImg] = useRecoilState(patient_profile_image);
    const [isprofileImgUpdated, setIsprofileImgUpdated] = useRecoilState(patient_profile_image_show)
    const [isMedicalTrackingDataUpdated, setIsMedicalTrackingDataUpdated] = useRecoilState(patient_medical_data_loading);
    const [isCustomMedicalTrackingDataUpdated, setIsCustomMedicalTrackingDataUpdated] = useRecoilState(is_patient_custom_medical_data_updated);
    const [isProfileUpdated, setIsProfileUpdated] = useRecoilState(is_patient_profile_data_updated);
    const [isSigneIn, setIsSigneIn] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [msg, setMsg] = useState("");
    const navigate = useNavigate();
    let id;
    const handleCloseModal = (e) => {
        setModalOpen(false);

    }

    const getAllMedicalCustomTrackingDataJwtToken = async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`, // Add the token to the request headers
        };
        try {
            if (isCustomMedicalTrackingDataUpdated == true || customMedicaltrackingData == "") {
                if (localStorage.getItem("patientid")) {
                    const response = await Axios.get(
                        `${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientHealthTracking/get_all_medical_custom_tracking_data?patients_id_fk=${localStorage.getItem("patientid")}`,
                        { headers }
                    );

                    if (response.data.status === '1') {
                        if (response.data.response.length > 0) {
                            setCustomMedicaltrackingData(response.data.response);
                            setIsCustomMedicalTrackingDataUpdated(false);
                        }
                    } else if (response.data.status === '401') {
                        logoutHandler();
                    } 
                }
            } else {
                const response = await Axios.get(
                    `${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientHealthTracking/get_all_medical_custom_tracking_data?patients_id_fk=${id}`,
                    { headers }
                );

                if (response.data.status === '1') {
                    if (response.data.response.length > 0) {
                        setCustomMedicaltrackingData(response.data.response);
                        setIsCustomMedicalTrackingDataUpdated(false);
                    }
                } else if (response.data.status === '401') {
                    logoutHandler();
                }
            }

        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access. Logging out.');
                message.error(error.response.data.message);
                logoutHandler();
            } else {
                console.error('Error:', error.message);
            }
        }

    }

    const getAllmedicalTrackingDataJwtToken = async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`, // Add the token to the request headers
        };
        try {
            if (isMedicalTrackingDataUpdated == true || medicaltrackingData == "") {
                if (localStorage.getItem("patientid")) {
                    Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientHealthTracking/get_all_medical_tracking_data?patients_id_fk=${localStorage.getItem("patientid")}`, { headers }).then((response) => {
                        console.log(response.data)
                        if (response.data.status == '1') {
                            if (response.data.response.length > 0) {
                                setMedicaltrackingData(response.data.response);
                                setIsMedicalTrackingDataUpdated(false);
                            }
                            else {
                                setMsg("No data is availble");
                            }
                        }
                    });
                }
            } else {
                Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientHealthTracking/get_all_medical_tracking_data?patients_id_fk=${id}`, { headers }).then((response) => {
                    console.log(response.data)
                    if (response.data.status == '1') {
                        if (response.data.response.length > 0) {
                            setMedicaltrackingData(response.data.response);
                            setIsMedicalTrackingDataUpdated(false);
                        }
                        else {
                            setMsg("No data is availble");
                        }
                    }
                });
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access. Logging out.');
                message.error(error.response.data.message);
                logoutHandler();
            } else {
                console.error('Error:', error.message);
            }
        }

    }

    useEffect(() => {
        setMsg("");
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`, // Add the token to the request headers
        };
        if (localStorage.getItem("phoneNo")) {
            setIsSigneIn(true);
            console.log("localStoragePhoneNo", localStorage.getItem("phoneNo"));

            let patientLocalData = JSON.parse(localStorage.getItem("patientLocalData"));
            console.log("Home useEffect: patientLocalData= ", patientLocalData);
            if (patientLocalData == null || patientLocalData == "" || isProfileUpdated == true || isprofileImgUpdated == true || profileImg == ""
                // || isMedicalTrackingDataUpdated == true || medicaltrackingData == "" || customMedicaltrackingData == ""
            ) {
                console.log("hii");
                let phoneno = localStorage.getItem("phoneNo");
                try {
                    Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientBioProfile/alldetails?phone=${phoneno}`, { headers }).then((response) => {
                        console.log("patient's all data", response.data.response[0]);
                        if (response.data.status == '1') {
                            id = response.data.response[0].id;
                            localStorage.setItem("patientid", id);
                            console.log("patientid=", id);
                            setPatientDetails(response.data.response[0]);
                            localStorage.setItem("patientLocalData", JSON.stringify(response.data.response[0]));
                            localStorage.setItem("patientUniqueId", response.data.response[0].up_id);
                            setIsProfileUpdated(false);
                        }

                        Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/commonapi/File_reader/read_file_content_patient?patientid=${response.data.response[0].up_id}&file=${response.data.response[0].profile_image}`).
                            then((response) => {
                                console.log("buffer file = ", response.data);
                                setProfileImg(response.data.base64String);
                                setIsprofileImgUpdated(false);
                            });


                        // Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientHealthTracking/get_all_medical_tracking_data?patients_id_fk=${id}`).then((response) => {
                        //     console.log(response.data)
                        //     if (response.data.status == '1') {
                        //         if (response.data.response.length > 0) {
                        //             setMedicaltrackingData(response.data.response);
                        //             setIsMedicalTrackingDataUpdated(false);
                        //         }
                        //         else {
                        //             setMsg("No data is availble");
                        //         }
                        //     }
                        // });
                        getAllmedicalTrackingDataJwtToken();


                        // try {
                        //     Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientHealthTracking/get_all_medical_custom_tracking_data?patients_id_fk=${id}`, { headers }).then((response) => {
                        //         console.log(response.data)
                        //         if (response.data.status == '1') {
                        //             if (response.data.response.length > 0) {
                        //                 setCustomMedicaltrackingData(response.data.response);
                        //                 setIsCustomMedicalTrackingDataUpdated(false);
                        //             }

                        //         } else if (response.data.status == '0') {
                        //             logoutHandler();
                        //         }
                        //     });
                        // } catch (error) {
                        //     if (error.response && error.response.status == 401) {
                        //         logoutHandler();
                        //     } else {
                        //         console.error(error.message)
                        //     }
                        // }
                        getAllMedicalCustomTrackingDataJwtToken();

                    });
                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        console.error('Unauthorized access. Logging out.');
                        message.error(error.response.data.message);
                        logoutHandler();
                    } else {
                        console.error('Error:', error.message);
                    }
                }

            }

            else {
                console.log("Bye");
                setPatientDetails(patientLocalData);

            }
            console.log(localStorage.getItem("patientid"));
            if (isMedicalTrackingDataUpdated == true || medicaltrackingData == "") {
                if (localStorage.getItem("patientid")) {
                    console.log("pranay");
                    // Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientHealthTracking/get_all_medical_tracking_data?patients_id_fk=${localStorage.getItem("patientid")}`).then((response) => {
                    //     console.log(response.data)
                    //     if (response.data.status == '1') {
                    //         if (response.data.response.length > 0) {
                    //             setMedicaltrackingData(response.data.response);
                    //             setIsMedicalTrackingDataUpdated(false);
                    //         }
                    //         else {
                    //             setMsg("No data is availble");
                    //         }
                    //     }
                    // });
                    getAllmedicalTrackingDataJwtToken();
                }

            }

            if (isCustomMedicalTrackingDataUpdated == true || customMedicaltrackingData == "") {
                if (localStorage.getItem("patientid")) {
                    console.log("garai");
                    // try {
                    //     Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientHealthTracking/get_all_medical_custom_tracking_data?patients_id_fk=${localStorage.getItem("patientid")}`, { headers }).then((response) => {
                    //         console.log(response.data)
                    //         if (response.data.status == '1') {
                    //             if (response.data.response.length > 0) {
                    //                 setCustomMedicaltrackingData(response.data.response);
                    //                 setIsCustomMedicalTrackingDataUpdated(false);
                    //             }

                    //         } else if (response.data.status == '0') {
                    //             logoutHandler();
                    //         }
                    //     });
                    // } catch (error) {
                    //     if (error.response && error.response.status == 401) {
                    //         logoutHandler();
                    //     } else {
                    //         console.error(error.message)
                    //     }
                    // }
                    console.log("getAllMedicalCustomTrackingDataJwtToken: Updated")
                    getAllMedicalCustomTrackingDataJwtToken();
                }
            }



        }
        else {
            setIsSigneIn(false);
        }
    }, [])


    const goToHealthProfile = () => {
        navigate("/PatientHealthProfile", { state: phoneNo });
    }
    const goToAddRecords = () => {
        navigate("/MedicalRecords", { state: phoneNo });
    }
    const goToConsultationHistory = () => {
        navigate("/ConsultationHistoryList", { state: phoneNo });
    }
    const goToDoctors = () => {
        navigate("/MyDoctors", { state: phoneNo });

    }
    const goToEditProfile = () => {
        navigate("/EditProfile", { state: patientDetails });

    }
    const goToContactUs = () => {
        navigate("/Tickets");

    }

    const logoutHandler = () => {
        localStorage.removeItem("phoneNo");
        localStorage.removeItem("patientid");
        localStorage.removeItem("patientLocalImage");
        localStorage.removeItem("patientLocalData");
        localStorage.removeItem("patientUniqueId");
        localStorage.removeItem("chronic_illness_data");
        localStorage.removeItem("allergic_information_data");
        localStorage.removeItem("family_relation_data");
        localStorage.removeItem("family_disease_data");
        localStorage.removeItem("token");

        setProfileImg("");
        setIsMedicalTrackingDataUpdated("");
        setIsCustomMedicalTrackingDataUpdated("");
        setIsprofileImgUpdated("");
        setIsProfileUpdated("");
        setMedicaltrackingData("");
        setIsSigneIn(false);
        navigate("/Login");

    }



    return (


        <>

            {isSigneIn ?
                <>
                    <div style={{ position: 'relative' }} >
                        <img onClick={() => setModalOpen(true)} src={poweroff} style={{ height: 30, width: 30, cursor: 'pointer', marginRight: 10, position: 'absolute', marginLeft: 320, marginTop: 27 }} />
                        <img src={heading} style={{ height: 80, width: '100%' }} />
                    </div>
                    <Dialog
                        open={modalOpen}
                        onClose={handleCloseModal}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Are you sure you want to Logout?"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">

                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>

                            <Button onClick={handleCloseModal}>Cancel</Button>

                            <Button onClick={logoutHandler} type="submit" autoFocus>
                                Logout
                            </Button>

                        </DialogActions>
                    </Dialog>
                    <div className="patient_details">
                        <div className="profile_picture" >
                            {profileImg ?
                                <img
                                    style={{ height: 90, width: 90, marginLeft: 17, borderRadius: '50%' }}
                                    src={profileImg}
                                />
                                :
                                <img
                                    style={{ height: 90, width: 90, marginLeft: 17, borderRadius: '50%' }}
                                    src={user}
                                />
                            }
                        </div>
                        <div className="details" >
                            <div style={{ textAlign: 'left', marginLeft: 25 }}>


                                <div>
                                    <p style={{ fontSize: 16, fontWeight: 'bold' }}>{patientDetails.first_name} {patientDetails.last_name}</p>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <p style={{ fontSize: 15, fontWeight: 'bold', marginTop: 4 }}>Unique Id: {patientDetails.up_id}</p>

                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <p style={{ fontSize: 15, fontWeight: 'bold', marginTop: 4 }}>Contact no: </p>
                                        <p style={{ fontSize: 15, fontWeight: '400', marginTop: 4 }}>&nbsp;{patientDetails.phone_number} </p>
                                    </div>

                                    {/* <div style={{ display: 'flex', flexDirection: 'row' }}>

                                        <p style={{ fontSize: 15, fontWeight: 'bold', marginTop: 4 }}>Blood Group: </p>
                                        {
                                            patientDetails.blood_group != null ?
                                                <p style={{ fontSize: 15, fontWeight: '400', marginTop: 4 }}>&nbsp;{patientDetails.blood_group} </p>
                                                :
                                                null
                                        }
                                    </div> */}
                                </div>

                            </div>

                        </div>

                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: 25 }}>
                        <div onClick={goToEditProfile} style={{ cursor: 'pointer' }}>

                            <button className="common-flex-button" > Bio Profile
                                <div>
                                    <img style={{ height: 25, width: 25, borderRadius: 5 }} src={editicon} />

                                </div>
                            </button>
                        </div>
                        <div onClick={goToHealthProfile} style={{ cursor: 'pointer' }}>
                            <button className="common-flex-button" > Health Profile
                                <div >
                                    <img style={{ height: 25, width: 25, borderRadius: 5 }} src={healthprofile} />

                                </div>
                            </button>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: 20 }}>
                        <div onClick={goToConsultationHistory} style={{ cursor: 'pointer' }}>

                            <button className="common-flex-button"  >Your Consultation
                                <div >
                                    <img style={{ height: 25, width: 25, borderRadius: 5 }} src={consulthistory} />

                                </div>
                            </button>
                        </div>
                        <div onClick={goToAddRecords} style={{ cursor: 'pointer' }}>

                            <button className="common-flex-button"  > Medical Records
                                <div >
                                    <img style={{ height: 25, width: 25, borderRadius: 5 }} src={medicalrecords} />

                                </div>
                            </button>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: 20 }}>
                        <div onClick={goToDoctors} style={{ cursor: 'pointer' }}>
                            <button className="common-flex-button" >My Doctors

                                <div >
                                    <img style={{ height: 25, width: 25, borderRadius: 5 }} src={doctorsicon} />

                                </div>
                            </button>
                        </div>
                        <div onClick={goToContactUs} style={{ cursor: 'pointer' }}>
                            <button className="common-flex-button"  >Customer Support
                                <div >
                                    <img style={{ height: 25, width: 25, borderRadius: 5 }} src={contact} />

                                </div>
                            </button>
                        </div>
                    </div>

                    <div className="divider"></div>
                    <div className="latest-medical-records">
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <p style={{ fontSize: 17, fontWeight: 'bold', color: 'black', marginLeft: 65 }}> Medical Tracking Info</p>
                            <FcPlus size={25} onClick={() => navigate("/SelectVitals")} style={{ marginLeft: 10, marginTop: 3 }} />
                        </div>



                        {msg ? <p style={{ marginLeft: 'auto', marginRight: 'auto', color: 'red', fontSize: 12, fontWeight: 'bold' }}>{msg}</p> : null}
                        <>
                            {medicaltrackingData && medicaltrackingData.map((val) => {
                                return (
                                    <div key={val.value}
                                        className="latest-vital-cards"
                                        onClick={() => navigate("/editVitals", { state: { orginalValue: val } })}
                                    >
                                        <div style={{ width: '55%', display: 'flex', flexDirection: 'row' }}>
                                            <div style={{ textAlign: 'left' }}>


                                                {val.icon == "heart_rate" ?
                                                    <img src={heart_rate} className='latest-vital-cards-img' />
                                                    : null
                                                }
                                                {val.icon == "weight_icon" ?
                                                    <img src={weight_icon} className='latest-vital-cards-img' />
                                                    : null
                                                }
                                                {val.icon == "blood_pressure" ?
                                                    <img src={blood_pressure} className='latest-vital-cards-img' />
                                                    : null
                                                }
                                                {val.icon == "blood_sugar" ?
                                                    <img src={blood_sugar} className='latest-vital-cards-img' />
                                                    : null
                                                }
                                                {val.icon == "body_temp" ?
                                                    <img src={body_temp} className='latest-vital-cards-img' />
                                                    : null
                                                }



                                            </div>
                                            <div style={{ textAlign: 'left' }}>

                                                <p style={{ fontWeight: '600', fontSize: 13, marginLeft: 5 }}  >{val.tracking_type}</p>
                                            </div>
                                        </div>
                                        <div >
                                            <div style={{ textAlign: 'right' }} >
                                                <p className='latest-vital-cards-value'>{val.value}</p>
                                            </div>
                                            <div style={{ textAlign: 'right' }} >
                                                <div className='flex-div' >
                                                    <p style={{ marginLeft: 45 }}>{moment(val.create_datetime).format('DD.MM.YYYY')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            {customMedicaltrackingData && customMedicaltrackingData.map((val) => {
                                return (
                                    <div key={val.value}
                                        className="latest-vital-cards"
                                        onClick={() => navigate("/customEditVitals", { state: val })}
                                    >
                                        <div style={{ width: '55%', display: 'flex', flexDirection: 'row' }}>
                                            {/* <div style={{ textAlign: 'left' }}>


                                                {val.icon == "heart_rate" ?
                                                    <img src={heart_rate} className='latest-vital-cards-img' />
                                                    : null
                                                }
                                               
                                            </div> */}
                                            <div style={{ textAlign: 'left' }}>

                                                <p style={{ fontWeight: '600', fontSize: 13, marginLeft: 25 }}  >{val.tracking_name}</p>
                                            </div>
                                        </div>
                                        <div >
                                            <div style={{ textAlign: 'right' }} >
                                                <p className='latest-vital-cards-value'>{val.tracking_value}</p>
                                            </div>
                                            <div style={{ textAlign: 'right' }} >
                                                <div className='flex-div' >
                                                    <p style={{ marginLeft: 45 }}>{moment(val.latest_date).format('DD.MM.YYYY')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            <div>
                                <p style={{ fontWeight: '600', fontSize: 13, marginLeft: 10, marginTop: 5, color: 'red' }}  > Click on + to add medical tracking data</p>
                            </div>
                        </>
                    </div>
                </>

                : <LandingPage />
            }

        </>
    )

}
