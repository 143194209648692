import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router';
import back from '../assets/images/back.png'
import $ from "jquery";
import { message } from 'antd'

const EditVaccination = () => {
    const [buttonHide, setButtonHide] = useState(false);
    const today = new Date();
    const numberOfDaysToAdd = 0;
    const date1 = today.setDate(today.getDate() + numberOfDaysToAdd);
    const defaultValue = new Date(date1).toISOString().split('T')[0] // yyyy-mm-dd
    const [date, setDate] = useState(defaultValue);
    const [dose, setDose] = useState("");
    const [msg, setMsg] = useState("");
    const [vaccinationName, setvaccinationName] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    let id = localStorage.getItem("patientid");
    console.log(id)
    const vaccinationNameHandeler = (e) => {
        setvaccinationName(e.target.value);
        setMsg("");
    }
    const doseHandeler = (e) => {
        setDose(e.target.value);
        setMsg("");
    }

    const datehandeler = (e) => {
        setDate(e.target.value);
        setMsg("");
    }
    const updateHandler = (e) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        if (vaccinationName != "" && dose != "" && date != "") {
            setButtonHide(true);
            // console.log(seesionId)

            e.preventDefault();
            let formdata = $(e.target);
            formdata = new FormData();
            formdata.append('patients_id_fk', id);
            formdata.append('name', vaccinationName);
            formdata.append('dose', dose);
            formdata.append('date', date);



            $.ajax({
                type: "POST",
                url: `${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientVaccinationInfo/insert`,
                data: formdata,
                processData: false,
                contentType: false,
                headers: headers,
                success(data) {
                    console.log("updated successfully", data);
                    setButtonHide(false);
                    navigate("/PatientHealthProfile");


                },
                error: function (err) {
                    if (err.status === 401) {
                        // Token expired, show message and log out
                        message.error(err.responseJSON.message);
                        logoutHandler();
                    } else {
                        console.error('Error occurred:', err.responseText);
                        alert("Error has occurred: ", err.responseText);
                    }
                }
            });
        }
        else {
            e.preventDefault();
            setMsg("all fields are mandatory");
        }
    }


    const logoutHandler = () => {
        localStorage.removeItem("phoneNo");
        localStorage.removeItem("patientid");
        localStorage.removeItem("patientLocalImage");
        localStorage.removeItem("patientLocalData");
        localStorage.removeItem("patientUniqueId");
        localStorage.removeItem("chronic_illness_data");
        localStorage.removeItem("allergic_information_data");
        localStorage.removeItem("family_relation_data");
        localStorage.removeItem("family_disease_data");
        localStorage.removeItem("token");

        navigate("/Login");

    }

    return (
        <div>
            <div>
                <div style={{ textAlign: 'left' }}>

                    <img src={back} style={{ height: 20, width: 20, marginTop: 20, marginLeft: 20 }} onClick={() => navigate("/PatientHealthProfile")} />
                </div>
                <p style={{ fontSize: 20, fontWeight: 'bold', color: '#A696FF', marginTop: -10 }}>Edit Vaccination</p>

                <div className='first-div'>
                    <div style={{ marginTop: 15 }} className="flex-div">
                        <div style={{ width: '45%', textAlign: 'left' }}>

                            <p className="label_style" >Vaccination Name:</p>
                        </div>
                        <div style={{ width: '55%' }}>

                            <div style={{ width: '95%' }} >
                                <input
                                    className="input_style"
                                    type="text"
                                    name="vaccinationName"
                                    required
                                    placeholder="Enter vaccination name"
                                    value={vaccinationName}
                                    onChange={(e) => vaccinationNameHandeler(e)}
                                    maxLength={25}
                                />
                            </div>
                        </div>

                    </div>

                    <div style={{ marginTop: 15 }} className="flex-div">
                        <div style={{ width: '45%', textAlign: 'left' }}>

                            <p className="label_style" >Dose:</p>
                        </div>
                        <div style={{ width: '55%' }}>

                            <div style={{ width: '95%' }} >
                                <input
                                    className="input_style"
                                    type="tel"
                                    name="name"
                                    required
                                    placeholder="Enter dose eg:1st or 2nd"
                                    value={dose}
                                    onChange={(e) => doseHandeler(e)}
                                    maxLength={1}
                                />
                            </div>
                        </div>

                    </div>

                    <div style={{ marginTop: 15 }} className="flex-div">
                        <div style={{ width: '45%', textAlign: 'left' }}>

                            <p className="label_style" >Date:</p>
                        </div>
                        <div style={{ width: '55%' }}>

                            <div style={{ width: '95%' }} >
                                <input
                                    className="input_style"
                                    type="date"
                                    name="name"
                                    required
                                    value={date}
                                    onChange={(e) => datehandeler(e)}

                                />
                            </div>
                        </div>

                    </div>



                    <p style={{ color: 'red', fontSize: 16, textAlign: 'left', marginLeft: 105, fontWeight: 'bold', marginTop: 10 }}>{msg}</p>


                    <form method="post"
                        onSubmit={(event) => updateHandler(event)}
                    >
                        {
                            buttonHide == false ?
                                <button style={{ marginTop: 20 }} type="submit" className="btn btn-auto btn-primary">
                                    Add
                                </button>
                                :
                                <p style={{ fontSize: 14, color: 'green', fontWeight: 'bold', marginTop: 15 }}>Saving...</p>

                        }


                    </form>

                </div>
            </div>
        </div>
    )
}

export default EditVaccination
