import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router';
import back from '../assets/images/back.png'
import { useNavigate } from "react-router-dom";
import plus from '../assets/images/plus.png';
import '../App.css';
import camera from '../assets/images/camera.png';
import Webcam from 'react-webcam';
import cameraSwitch from '../assets/images/cameraSwitch.png';
import select from '../assets/images/select.jpg';
import cross from '../assets/images/cross.png';
import $ from "jquery";
import Axios from 'axios';
import documents from '../assets/images/documents.png';
import deleteicon from '../assets/images/delete-icon.jpg';
import folder from '../assets/images/folder.png';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Collapsible from 'react-collapsible';
import Spinner from '../components/Spinner';
import { message } from 'antd'

export default () => {
    const navigate = useNavigate();
    const [addRecordButtonHide, setAddRecordButtonHide] = useState(false);
    const [openCameraButton, setOpenCameraButton] = useState(false);
    const [cameraOpen, setCameraOpen] = useState(false);
    const [capturedImage, setCapturedImage] = useState("");
    const [base64ImageToFile, setBase64ImageToFile] = useState("");
    const [file_type, setFile_type] = useState("");
    const [isCapturePhotoHide, setIsCapturePhotoHide] = useState(false);
    const [isOpenDocumentCategory, setIsOpenDocumentCategory] = useState(false);
    const [reportsCategory, setReportsCategory] = useState("");
    const [document_name, setDocument_name] = useState("");
    const [isButtonHide, setIsButtonHide] = useState(false);
    const [uploadedDocuments, setUploadedDocuments] = useState([]);
    const [isEmptyDocumentName, setIsEmptyDocumentName] = useState(false);
    const [noRecords, setNoRecords] = useState(false);
    const [isDeleteDoc, setIsDeleteDoc] = useState(false);
    const [uploadedDocId, setUploadedDocId] = useState("");
    const [refreshUploadedDoc, setRefreshUploadedDoc] = useState(false);
    const [uploadedFolder, setUploadedFolder] = useState([]);
    const [uploadedFile, setUploadedFile] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isNoFile, setIsNoFile] = useState(false);
    const [isOpenCollapse, setIsOpenCollapse] = useState(false);
    const [isInputSectionOpen, setIsInputSectionOpen] = useState(false);
    const [backButtonHide, setBackButtonHide] = useState(false);
    const [doctorsName, setDoctorsName] = useState("");
    const [visitingPurpose, setVisitingPurpose] = useState("");
    const [seesionId, setSeesionId] = useState(Number);
    const [buttonHide, setButtonHide] = useState(false);
    const [loading, setLoading] = useState(true);
    let pid;

    const location = useLocation()


    const FACING_MODE_USER = "user";
    const FACING_MODE_ENVIRONMENT = "environment";

    const videoConstraints = {
        facingMode: FACING_MODE_ENVIRONMENT
    };

    const [facingMode, setFacingMode] = useState(FACING_MODE_ENVIRONMENT);
    const [open, setOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const handleClick = useCallback(() => {
        setFacingMode(
            prevState =>
                prevState === FACING_MODE_ENVIRONMENT
                    ? FACING_MODE_USER
                    : FACING_MODE_ENVIRONMENT
        );
    }, []);

    const backHandler = () => {
        global.OTPValidation = 'true';
        navigate("/");
    }
    // const handleClickOpen = () => {
    //     setOpen(true);
    // };
    const webcamRef = React.useRef(null);

    const startCam = () => {
        console.log("camera is opening");
        setCameraOpen(true);
        setIsCapturePhotoHide(false);
        setRefreshUploadedDoc(false);
    }

    const addRecordsHandler = () => {
        setAddRecordButtonHide(true);
        setOpenCameraButton(true);
    }
    const backToRecordsHandler = () => {
        setIsInputSectionOpen(false);
        setBackButtonHide(true);
        setAddRecordButtonHide(false);
        setOpenCameraButton(false);
        setCameraOpen(false);
        setCapturedImage("");


    }
    const selectedPhotoHandler = () => {
        setIsOpenDocumentCategory(true);

    }

    const reportsCategoryChangeHandler = (e) => {
        setReportsCategory(e.target.value);
        if (e.target.value == "Prescription") {
            setDocument_name("Prescription");
        }

    }

    const capturePhoto = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            // console.log("captured image ", imageSrc);
            setCapturedImage(imageSrc);
            setIsCapturePhotoHide(true);

            console.log("captured photo  ", imageSrc);
            const url = imageSrc;
            fetch(url)
                .then(res => res.blob())
                .then(blob => {
                    const file = new File([blob], "Filename.jpeg", { type: "image/jpeg" })
                    console.log(file);
                    setBase64ImageToFile(file);
                    setFile_type("image/jpeg");
                })


        },
        [webcamRef]
    );
    const documentNameChangeHandler = (e) => {
        setDocument_name(e.target.value);

    }

    useEffect(() => {

        const getUploadFolder = async () => {
            const token = localStorage.getItem('token');
            const headers = {
                Authorization: `Bearer ${token}`, // Add the token to the request headers
            };

            setRefreshUploadedDoc(true);

            pid = localStorage.getItem("patientid");
            console.log(pid);
            setSeesionId(pid)

            try {

                const response = await Axios.get(
                    `${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientMedicalRecord/get_uploaded_folder?patients_id_fk=${pid}`,
                    { headers }
                );

                console.log("Uploaded folder success response", response.data);
                setLoading(false);

                if (response.data.status == '1') {
                    if (response.data.response.length > 0) {
                        setUploadedFolder(response.data.response);

                    } else {
                        setNoRecords(true);

                    }
                } else if (response.data.status === '401') {
                    logoutHandler();
                } else if (response.data.status === '0') {
                    message.error('An error occurred. Please try again.');
                }

            } catch (error) {
                if (error.response && error.response.status === 401) {
                    console.error("Unauthorized access. Logging out.");
                    message.error(error.response.data?.message || 'Unauthorized');
                    logoutHandler();
                } else {
                    console.error("Error:", error.message);
                    message.error('An error occurred. Please try again.');
                }
            }
        }
        getUploadFolder();

    }, [])

    const logoutHandler = () => {
        localStorage.removeItem("phoneNo");
        localStorage.removeItem("patientid");
        localStorage.removeItem("patientLocalImage");
        localStorage.removeItem("patientLocalData");
        localStorage.removeItem("patientUniqueId");
        localStorage.removeItem("chronic_illness_data");
        localStorage.removeItem("allergic_information_data");
        localStorage.removeItem("family_relation_data");
        localStorage.removeItem("family_disease_data");
        localStorage.removeItem("token");

        navigate("/Login");

    }

    const handleDeleteClickOpen = (id) => {
        setDeleteOpen(true);
        setUploadedDocId(id);
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
    };


    const uploadRecords = (e) => {

        console.log(pid)
        if (document_name != "") {
            setIsButtonHide(true);
            e.preventDefault();
            let formdata = $(e.target);
            formdata = new FormData();
            formdata.append('file', base64ImageToFile);
            $.ajax({
                type: "POST",
                url: `${process.env.REACT_APP_GLOBAL_BASEURL}/api/commonapi/FileUpload/file_insert_patientapp`,
                data: formdata,
                processData: false,
                contentType: false,
                success(data) {
                    console.log("savehandler:file upload success response:  ", data);
                    // alert("file upload successfully");

                    if (data) {
                        const filedata = new FormData();
                        filedata.append("file", `${process.env.REACT_APP_GLOBAL_BASEURL}/${data}`);
                        filedata.append('patients_id_fk', pid);
                        filedata.append('category', reportsCategory);
                        filedata.append('document_name', document_name);
                        filedata.append('document_type', file_type);

                        $.ajax({
                            type: "POST",
                            url: `${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientMedicalRecord/insert`,
                            data: filedata,
                            processData: false,
                            contentType: false,
                            success(data) {
                                console.log("upload recordshandler:records upload success response:  ", data);
                                setIsButtonHide(false);
                                setRefreshUploadedDoc(true);

                                Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientMedicalRecord/get_uploaded_documents?patients_id_fk=${pid}`).then((response) => {
                                    console.log(" uploaded documents success response ", response.data);
                                    if (response.data.status == '1') {
                                        if (response.data.response.length > 0) {
                                            setUploadedDocuments(response.data.response);
                                        } else {
                                            setNoRecords(true);
                                        }
                                    }
                                    setAddRecordButtonHide(false);
                                    setOpenCameraButton(false);
                                    setCameraOpen(false);
                                    setCapturedImage("");
                                    setReportsCategory("");
                                    setIsOpenDocumentCategory(false)

                                });



                            },
                            error: function (err) {
                                console.log(err.responseText);
                                alert("error has occured  ", err);
                            }
                        });

                    }
                },
                error: function (err) {
                    console.log(err.responseText);
                    alert("error has occured  ", err);
                }
            });
        }
        else {
            e.preventDefault();
            console.log("documnt is empty")
            setIsEmptyDocumentName(true);
        }

    }


    const uploadFolder = (e) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        setButtonHide(true);
        // console.log(seesionId)
        if (doctorsName != "" && visitingPurpose != "") {

            e.preventDefault();
            let formdata = $(e.target);
            formdata = new FormData();
            formdata.append('patients_id_fk', seesionId);
            formdata.append('doctors_name', doctorsName);
            formdata.append('visiting_purpose', visitingPurpose);

            $.ajax({
                type: "POST",
                url: `${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientMedicalRecord/create_folder`,
                data: formdata,
                processData: false,
                contentType: false,
                headers: headers,
                success(data) {
                    console.log("created folder successfully", data);
                    setButtonHide(false);
                    window.location.reload(false);


                },
                error: function (err) {
                    if (err.status === 401) {
                        // Token expired, show message and log out
                        message.error(err.responseJSON.message);
                        logoutHandler();
                    } else {
                        console.error('Error occurred:', err.responseText);
                        alert("Error has occurred: ", err.responseText);
                    }
                }
            });
        } else {
            e.preventDefault();
            setButtonHide(false);
            alert(" Doctors name and visiting purpose are mandatory");


        }
    }


    const openNewTabHandler = (file) => {
        const url = `${file}`;
        window.open(url, "toolbar=yes,scrollbars=yes,resizable=yes,width=400,height=400");
    }

    const addFolderHandler = () => {
        setAddRecordButtonHide(true);
        setIsInputSectionOpen(true);

    }

    const handleChangeDoctorsName = (e) => {
        setDoctorsName(e.target.value);

    }
    const handleChangeVisitingPurpose = (e) => {
        setVisitingPurpose(e.target.value);

    }


    const deleteDocumentsHandler = (e) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        setIsDeleteDoc(true);
        e.preventDefault();
        let formdata = $(e.target);
        formdata = new FormData();
        formdata.append('id', uploadedDocId);

        $.ajax({
            type: "POST",
            url: `https://connectedbytes.in/emr-clinic-rest-api/patientapi-php/api/patientapi/PatientMedicalRecord/delete_documents?id=${uploadedDocId}`,
            data: formdata,
            processData: false,
            contentType: false,
            headers: headers,
            success(data) {
                console.log("deleted document response", data);
                // alert("Document deleted");
                setIsDeleteDoc(false);
                setDeleteOpen(false);
                setRefreshUploadedDoc(false);
                window.location.reload(false);


            },
            error: function (err) {
                if (err.status === 401) {
                    // Token expired, show message and log out
                    message.error(err.responseJSON.message);
                    logoutHandler();
                } else {
                    console.error('Error occurred:', err.responseText);
                    alert("Error has occurred: ", err.responseText);
                }
                setIsDeleteDoc(false);
            }
        });
    }

    const openCollapsibleHandler = (id) => {
        setIsOpenCollapse(true);
        setIsLoading(true);
        setUploadedFile([])

        Axios.get(`${process.env.REACT_APP_GLOBAL_BASEURL}/api/patientapi/PatientMedicalRecord/get_uploaded_file_by_folder_id?folder_id=${id}`).then((response) => {
            console.log(" uploaded file success response ", response.data);
            setIsLoading(false);
            if (response.data.length > 0) {
                setIsNoFile(false);

                setUploadedFile(response.data);
            } else {
                setIsNoFile(true);
            }

        });
    }


    return (
        <>
            {
                loading ? <Spinner /> :
                    <>
                        <div style={{ textAlign: 'left' }}>

                            <img src={back} style={{ height: 20, width: 20, marginTop: 15, marginLeft: 20 }} onClick={backHandler} />
                        </div>

                        <p style={{ fontSize: 20, fontWeight: 'bold', color: '#A696FF', marginTop: -10 }}>Medical-Records</p>
                        <div style={{ height: '100%', width: '100%' }}>





                            <div style={{ height: '65%', width: '100%', marginTop: 25 }}>

                                {
                                    refreshUploadedDoc == true && cameraOpen == false ?
                                        <>
                                            {/* <p style={{ fontSize: 15, fontWeight: 'bold', textAlign: 'left', marginLeft: 20 }}>Tap on icon to see files below</p> */}
                                            {
                                                uploadedFolder.map((details) => {
                                                    return (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                            }}
                                                        >
                                                            <div
                                                                className="medical-records-cards"
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-between',
                                                                    padding: '10px 15px',
                                                                    margin: '5px 0',
                                                                    backgroundColor: '#fff',
                                                                    borderRadius: '8px',
                                                                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                                                                    cursor: 'pointer',
                                                                    width: '90%', // Adjust to control item width
                                                                    maxWidth: '400px', // Set a max width for the cards
                                                                }}

                                                            >
                                                                <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => {
                                                                    navigate('/ViewMedicalRecors', { state: details });
                                                                }}>
                                                                    <img
                                                                        src={folder}
                                                                        alt="Folder Icon"
                                                                        style={{
                                                                            height: '30px',
                                                                            width: '30px',
                                                                            marginRight: '10px',
                                                                        }}
                                                                    />
                                                                    <div style={{
                                                                        marginLeft: 5,
                                                                    }}>
                                                                        <p
                                                                            style={{
                                                                                fontSize: '16px',
                                                                                fontWeight: 'bold',
                                                                                margin: '0',
                                                                            }}
                                                                        >
                                                                            {details.doctors_name}
                                                                        </p>
                                                                        <p
                                                                            style={{
                                                                                fontSize: '14px',
                                                                                color: '#555',
                                                                            }}
                                                                        >
                                                                            {details.visiting_purpose}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div style={{ textAlign: 'center' }}>
                                                                    <p
                                                                        style={{
                                                                            fontSize: '14px',
                                                                            fontWeight: '600',
                                                                            margin: '0',
                                                                        }}
                                                                    >
                                                                        {details.date}
                                                                    </p>
                                                                    <img
                                                                        src={deleteicon}
                                                                        alt="Delete Icon"
                                                                        style={{
                                                                            height: '25px',
                                                                            width: '25px',
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation(); // Prevent triggering navigation
                                                                            handleDeleteClickOpen(details.id);
                                                                        }}
                                                                    />
                                                                    <Dialog
                                                                        open={deleteOpen}
                                                                        onClose={handleDeleteClose}
                                                                        aria-labelledby="alert-dialog-title"
                                                                        aria-describedby="alert-dialog-description"
                                                                    >
                                                                        <DialogTitle id="alert-dialog-title">
                                                                            {"Are you sure you want to delete this document?"}
                                                                        </DialogTitle>
                                                                        <DialogContent>
                                                                            <DialogContentText id="alert-dialog-description"></DialogContentText>
                                                                        </DialogContent>
                                                                        <DialogActions>
                                                                            <Button onClick={handleDeleteClose}>Cancel</Button>
                                                                            {isDeleteDoc == false ? (
                                                                                <form
                                                                                    method="post"
                                                                                    onSubmit={(event) =>
                                                                                        deleteDocumentsHandler(event)
                                                                                    }
                                                                                >
                                                                                    <Button type="submit" autoFocus>
                                                                                        Delete
                                                                                    </Button>
                                                                                </form>
                                                                            ) : (
                                                                                <p
                                                                                    style={{
                                                                                        color: "red",
                                                                                        fontSize: 15,
                                                                                        fontWeight: "bold",
                                                                                        marginLeft: 10,
                                                                                        marginTop: -5,
                                                                                    }}
                                                                                >
                                                                                    Deleting...
                                                                                </p>
                                                                            )}
                                                                        </DialogActions>
                                                                    </Dialog>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })

                                            }

                                        </>
                                        :
                                        null
                                }


                            </div >
                            <div className='first-div'>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ textAlign: 'left', paddingLeft: 20 }}>
                                        <p style={{ marginLeft: -8, fontSize: 16, marginTop: 12, fontWeight: '500', color: 'rgb(34, 189, 250)' }} > &nbsp;&nbsp;Step 1</p>
                                        <p style={{ fontSize: 16, height: 24 }} >Click on 'Add folder' button to create new folder, provide doctor name and visiting purpose in popup to name the folder
                                            .</p>
                                    </div>


                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 25 }}>
                                    <div style={{ textAlign: 'left', paddingLeft: 20 }}>
                                        <p style={{ marginLeft: -8, fontSize: 16, marginTop: 35, fontWeight: '500', color: 'rgb(34, 189, 250)' }} > &nbsp;&nbsp;Step 2</p>
                                        <p style={{ fontSize: 16, height: 24 }} >Click on folder card to open Medical Record View page.
                                            .</p>
                                    </div>


                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 25 }}>
                                    <div style={{ textAlign: 'left', paddingLeft: 20 }}>
                                        <p style={{ marginLeft: -8, fontSize: 16, marginTop: 12, fontWeight: '500', color: 'rgb(34, 189, 250)' }} > &nbsp;&nbsp;Step 3</p>
                                        <p style={{ fontSize: 16, height: 105 }} > In Medical Record View screen view or add records like prescriptions lab reports under that folder.
                                            Click on plus sign button to add new records.
                                            . </p>
                                    </div>


                                </div>

                            </div>
                            <div style={{ height: '35%', width: '100%' }}>


                                <div style={{ display: 'flex', paddingRight: 40, paddingTop: 30, flexDirection: 'row', justifyContent: 'flex-end' }}>

                                    {
                                        addRecordButtonHide == false ?

                                            <div className="add-records" onClick={addFolderHandler}  >
                                                <img src={plus} style={{ height: 22, width: 30, marginTop: 20, textAlign: 'center' }} />
                                                <p style={{ fontSize: 12, fontWeight: '600', marginTop: 20, width: 80, textAlign: 'left' }}>Add Folder</p>

                                            </div>
                                            :
                                            null
                                    }

                                    {
                                        isInputSectionOpen ?
                                            <>

                                                <div className="first-div" style={{ width: '500px', marginRight: -25 }}>
                                                    <h3 style={{ color: 'black' }}>Add Folder</h3>

                                                    <p style={{ fontSize: 14, fontWeight: '600', textAlign: 'left', marginLeft: 25 }} >Doctor's Name </p>
                                                    <div style={{ width: '100%', textAlign: 'left', marginLeft: 25 }} >
                                                        <input
                                                            className="input_style"
                                                            value={doctorsName}
                                                            maxLength={24}
                                                            onChange={(event) => handleChangeDoctorsName(event)} type="text" required

                                                        />
                                                    </div>
                                                    <p style={{ fontSize: 14, fontWeight: '600', textAlign: 'left', marginLeft: 25 }} >Visiting Purpose </p>
                                                    <div style={{ width: '100%', textAlign: 'left', marginLeft: 25 }} >
                                                        <input
                                                            className="input_style"
                                                            value={visitingPurpose}
                                                            maxLength={30}
                                                            onChange={(event) => handleChangeVisitingPurpose(event)} type="text" required

                                                        />
                                                    </div>

                                                    <form method="post"
                                                        onSubmit={(event) => uploadFolder(event)}
                                                    >
                                                        {
                                                            buttonHide == false ?
                                                                <button type="submit" style={{ marginTop: 20, height: 40, width: 80, color: 'white', backgroundColor: 'green', borderRadius: 7, fontSize: 13, fontWeight: 'bold' }}>
                                                                    Add
                                                                </button>
                                                                :
                                                                <p style={{ fontSize: 14, color: 'green', fontWeight: 'bold' }}>Adding</p>

                                                        }

                                                        <button onClick={backToRecordsHandler} style={{ marginLeft: 10, marginTop: 20, height: 40, width: 80, color: 'white', backgroundColor: 'red', borderRadius: 7, fontSize: 13, fontWeight: 'bold' }}>
                                                            Cancel
                                                        </button>
                                                    </form>
                                                </div>
                                            </>
                                            :
                                            null

                                    }
                                </div>

                            </div>

                        </div >
                    </>
            }
        </>
    )
}